let currentTooltip = null
export default {
    bind(el, binding) {

        const tooltipElement = document.createElement('div')

        if (binding.value.block) {
            hideTooltip()
            return
        }

        const tooltipText = binding.value.text || 'Default Tooltip Text'
        const position = binding.value.position || 'top'
        const activation = binding.value.activation || 'hover'
        const color = binding.value.color || 'rgba(0, 0, 0, 0.8)'
        const iconClass = binding.value.icon || 'icon-info-cr-fr'
        const width = binding.value.width || 'max-content'


        tooltipElement.className = `custom-tooltip ${position}`
        tooltipElement.innerHTML = `
            <div>
              <i class="${iconClass}"></i> 
              <p>${tooltipText}</p>
              <div class="arrow" id="custom-tooltip-arrow"></div>
            </div>
        `
        tooltipElement.style.backgroundColor = color
        tooltipElement.style.width = width
        const arrow = tooltipElement.querySelector('.arrow')
        arrow.style.borderLeftColor = color

        el.appendChild(tooltipElement)
        function showTooltip() {
            if (currentTooltip !== null && currentTooltip !== tooltipElement) {
                currentTooltip.style.display = 'none'
            }

            const rect = el.getBoundingClientRect();

            switch (position) {
                case 'top':
                    tooltipElement.style.left = (rect.left + rect.width / 2 + window.scrollX) + 'px';
                    tooltipElement.style.top = (rect.top + window.scrollY) - 10 + 'px';
                    tooltipElement.style.transform = 'translateX(-50%) translateY(-100%)';
                    break;
                case 'bottom':
                    tooltipElement.style.left = (rect.left + rect.width / 2 + window.scrollX) + 'px';
                    tooltipElement.style.top = (rect.bottom + window.scrollY) + 10 + 'px';
                    tooltipElement.style.transform = 'translateX(-50%)';
                    break;
                case 'left':
                    tooltipElement.style.left = (rect.left + window.scrollX) - 10 + 'px';
                    tooltipElement.style.top = (rect.top + rect.height / 2 + window.scrollY) + 'px';
                    tooltipElement.style.transform = 'translateX(-100%) translateY(-50%)';
                    break;
                case 'right':
                    tooltipElement.style.left = (rect.right + window.scrollX) + 'px';
                    tooltipElement.style.top = (rect.top + rect.height / 2 + window.scrollY) + 'px';
                    tooltipElement.style.transform = 'translateY(-50%)';
                    break;
                default:
                    break;
            }

            tooltipElement.style.display = 'block'
            currentTooltip = tooltipElement
        }

        function hideTooltip() {
            tooltipElement.style.display = 'none'
            currentTooltip = null
        }

        if (activation === 'hover') {
            el.addEventListener('mouseenter', showTooltip)
            el.addEventListener('mouseleave', hideTooltip)
            return
        }

        el.addEventListener('click', function() {
            tooltipElement.style.display === 'block'
                ? hideTooltip()
                : showTooltip()
        })

        document.addEventListener('click', function(event) {
            if (!el.contains(event.target)) {
                hideTooltip()
            }
        })
    }
}
