import type { FilterLoads } from '@/modules/loads-deliveries/interfaces/FilterLoads'
import { filtersDefault } from '@/modules/loads-deliveries/const/LoadsFilterDefault'
import { LoadStatus } from '@/modules/loads-deliveries/enums/LoadStatus'
import { ActionContext, Module } from 'vuex'

type State = {
  currentFilter: FilterLoads,
}

interface RootState {}

type FilterLoadsContext = ActionContext<State, RootState>

const state = {
  currentFilter: structuredClone(filtersDefault)
}

const mutations = {
  reset(state: State) {
    state.currentFilter = structuredClone(filtersDefault)
  },

  update(state: State, payload: { field: keyof FilterLoads , value: string | LoadStatus[] }) {
    (state.currentFilter[payload.field] as typeof payload.value) = payload.value
  },
}

const actions = {
  resetFilter({ commit }: FilterLoadsContext) {
    commit('reset')
  },

  updateField({commit}: FilterLoadsContext, payload: {field: keyof FilterLoads, value: string}) {
    commit('update', payload)
  }
}

const getters = {
  current: (state: State): FilterLoads => state.currentFilter
}

export const loadsFilter: Module<State, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}