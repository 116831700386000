import { Role } from '@/shared/enums/Role'
import { MenuItem } from '@/shared/enums/MenuItem'

export default [
    {
        path: '/accounts-payable',
        name: MenuItem.AccountsPayable,
        component: () => import('@/modules/accounts-payable/views/AccountsPayable.vue'),
        meta: { requireRoles: [Role.Admin, Role.FinancialManagement, Role.PlanningAuditor, Role.LegacyAccess] },
    }
]
