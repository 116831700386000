import axios from 'axios';
import {defaultState, mutations, getters} from '@/plugins/store/defaultServiceState'

export default {
    namespaced: true,
    state: {
        token: '374c188b-9f11-4081-a379-aa941da82bf7',
        ...defaultState
    },
    mutations: {
        ...mutations
    },
    getters: {
        token(state) {
            return state.token;
        },
        ...getters
    },
    actions: {
        fetchAddress: ({commit, state}, zip) => {
            const request = `https://api.copomex.com/query/info_cp/${zip}?token=${state.token}`;
            commit('isLoading', true);
            axios.post(request).then(function (response) {
                commit('error', null);
                const data = response.data.map(r => r.response);

                commit('itemsZipCode', {...state.items, zips: data});
                commit('isLoading', false);
            }).catch(function () {
                commit('isLoading', false);
                commit('items', {});
                const e = {type: 'error', data: 'El código postal no pudo ser encontrado'};
                commit('app/reportMessage', e, {root: true});
            });

        },
        // -----------------------------------------------------
        // Obtener municipalidad dado un estado
        // -----------------------------------------------------
        fetchMunicipalities: ({commit, state}, address_state) => {
            const request = `https://api.copomex.com/query/get_municipio_por_estado/${address_state}?token=${state.token}`;
            commit('isLoading', true);
            axios.post(request).then(function (response) {
                commit('error', null);
                const municipalities = response.data.response.municipios.sort();
                commit('itemsZipCode', {...state.items, municipalities: municipalities});
                commit('isLoading', false);
            }).catch(error => {
                commit('isLoading', false);
                commit('itemsZipCode', {});
                const e = {type: 'error', data: JSON.stringify(error)};
                commit('app/reportMessage', e, {root: true});
            });
        },
        // -----------------------------------------------------
        // Obtener localidad o ciudad dado un estado y municipio
        // -----------------------------------------------------
        fetchCities: ({commit, state}, payload) => {
            const {address_state, municipality} = payload;
            commit('isLoading', true);
            const request = `https://api.copomex.com/query/get_localidad_por_estado_municipio/?estado=${address_state}&municipio=${municipality}&token=${state.token}`;
            axios.post(request).then(function (response) {
                commit('error', null);

                const {localidad_clave} = response.data.response;
                const cities = Object.keys(localidad_clave).sort();
                commit('itemsZipCode', {...state.items, cities: cities});
                commit('isLoading', false);
            }).catch(error => {
                commit('isLoading', false);
                commit('itemsZipCode', {});
                const e = {type: 'error', data: JSON.stringify(error)};
                commit('app/reportMessage', e, {root: true});
            });
        },
        // -----------------------------------------------------
        // Obtener colonia dado una municipalidad
        // -----------------------------------------------------
        fetchNeighborhoods: ({commit, state}, municipality) => {
            commit('isLoading', true);
            const request = `https://api.copomex.com/query/get_colonia_por_municipio/${municipality}?token=${state.token}`;
            axios.post(request).then(function (response) {
                commit('error', null);
                const {colonia} = response.data.response;
                commit('itemsZipCode', {...state.items, neighborhoods: colonia.sort()});
                commit('isLoading', false);
            }).catch(error => {
                commit('isLoading', false);
                commit('itemsZipCode', {});
                const e = {type: 'error', data: JSON.stringify(error)};
                commit('app/reportMessage', e, {root: true});
            });
        }
    },

}