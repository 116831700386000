import {defaultState, getters, mutations} from '@/plugins/store/defaultServiceState'
import store from "@/plugins/store";
import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        ...defaultState
    },
    getters: {
        ...getters
    },
    mutations: {
        ...mutations
    },
    actions: {
        saveShareholders({commit, dispatch}, shareholders) {
            const token = store.state.security.jwt;
            const product_name = Vue.prototype.$session.exists('product_name') ? Vue.prototype.$session.get('product_name') : null
            commit('isSaving', true);
            Vue.prototype.$axios.post("/api/v1/carriers/shareholders", {product_id: product_name, shareholders}, {
                    headers: {
                        "Authorization": `${token}`,
                    }
                }
            )
                .then(function (response) {
                    commit('item', response.data.data);
                    commit('error', null);

                    const w = {...store.state.waiting_status.item};
                    w.need_wait = true;
                    commit('waiting_status/item', w, {root: true});
                    dispatch('waiting_status/carrierStatus', token, {root: true});
                    commit('isSaving', false);
                })
                .catch(error => {
                    commit('isSaving', false);
                    commit('error', error.response.data.code || error.message);
                });
        },

    }
}