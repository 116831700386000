import { Pagination } from '@/ui-lib/atoms/custom-pagination/type/Pagination'
import { paginationConfig } from '@/ui-lib/atoms/custom-pagination/utils/PaginationConfig'
import { buildQueryPaginate } from '@/ui-lib/atoms/custom-pagination/utils/BuildQueryParams'

type stateType = {
  currentPagination: Pagination,
  persistPagination: boolean
}

const state = {
  currentPagination: {
    page: paginationConfig.page,
    itemsPerPage: paginationConfig.itemsPerPage,
    queryPagination: buildQueryPaginate(paginationConfig),
  },
}

const mutations = {

  set(state: stateType, pagination: Pagination) {
    state.currentPagination.page = pagination.page
    state.currentPagination.itemsPerPage = pagination.itemsPerPage
    state.currentPagination.queryPagination = buildQueryPaginate(pagination)
  },

  setNext(state: stateType) {
    state.currentPagination.page = state.currentPagination.page += 1
  },

  setPrev(state: stateType) {
    state.currentPagination.page = state.currentPagination.page -= 1
  },

  reset(state: stateType) {
    state.currentPagination = {
      page: paginationConfig.page,
      itemsPerPage: paginationConfig.itemsPerPage,
      queryPagination: buildQueryPaginate(paginationConfig),
    }
  },
}

const actions = {
  updatePagination({ commit }: any, pagination: Pagination) {
    commit('set', pagination)
  },

  nextPage({ commit }: any) {
    commit('setNext')
  },

  prevPage({ commit }: any) {
    commit('setPrev')
  },

  resetPagination({ commit }: any) {
    commit('reset')
  },
}

const getters = {
  current: (state: stateType): Pagination => state.currentPagination
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}