import Vue from 'vue';
import Vuex from 'vuex';
import {File} from '@/shared/components/loading-loads-files-analyzer/interfaces/File';

Vue.use(Vuex)

interface State {
    show: boolean;
    files: File[];
}

const store = new Vuex.Store<State>({
    state: {
        show: false,
        files: []
    },
    mutations: {
        setShow(state, showing: boolean): void {
            state.show = showing;
        },
        setFiles(state, files: File[]): void {
            state.files = files;
        },
        addFile(state, newFile: File): void {
            state.files.push(newFile)
        },
        updateFile(state, fileData: File): void {
            const {uploadId} = fileData
            const requestedFileIndex = state.files.findIndex((file: File) => file.uploadId === uploadId)
            if (requestedFileIndex !== -1) {
                Object.assign(state.files[requestedFileIndex], fileData);
                return
            }
            state.files.push(fileData)
        },
        removeFile(state, id: string): void {
            state.files = state.files.filter(file => file.uploadId !== id);
        }
    },
    actions: {
        toggleShow({commit}, value: boolean) {
            commit('setShow', value);
        },
        setFiles({commit}, files: File[]): void {
            commit('setFiles', files);
        },
        addFile({commit}, newFile: File[]): void {
            commit('addFile', newFile);
        },
        updateFile({commit}, fileData: File): void {
            commit('updateFile', fileData)
        },
        removeFile({commit}, id: string): void {
            commit('removeFile', id)
        }
    },
    getters: {
        isShowing: (state: State): boolean => state.files.length !== 0,
        getFiles: (state: State): File[] => state.files,
    },
});

export default store;
