import { mapActions, mapGetters } from 'vuex'
import { obtainChannel } from '@/shared/notifications/pusher-config'

export default {
  computed: {
    ...mapGetters('security', {
      token: 'getJwt',
      userId: 'getUserId',
      supplierTaxId: 'getSupplierTaxId',
      isSupplier: 'isSupplier',
    }),
  },
  data() {
    return {
      channelKey: null,
    }
  },
  mounted() {
    this.channelKey = this.isSupplier ? this.supplierTaxId : this.userId
  },
  watch: {
    token(token) {
      if (token) {
        this.channelKey = this.isSupplier ? this.supplierTaxId : this.userId
      }
    },
  },
  methods: {
    ...mapActions('waiting_status', ['carrierStatus']),
    subscribeAndHandleEvent(eventName, callBack) {
      if (!this.channelKey) {
        return
      }
      const channel = obtainChannel(this.channelKey)
      if (channel) {
        channel.bind(eventName, callBack)
      }
    },
    unsubscribeFromEvent(eventName) {
      if (!this.channelKey) {
        return
      }

      const channel = obtainChannel(this.channelKey)
      if (channel) {
        channel.unbind(eventName)
      }
    },
  },
}
