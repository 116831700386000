import axios from '@/plugins/axios';

const fetchProductInfoByBusinessId = async (trackId) =>{
    const url = `/api/v1/accounts_payable/business?predicates[id]=${trackId}`;
        return await axios.axiosInstance({
            url,
            method: 'GET'
        });
}

export {fetchProductInfoByBusinessId}