import {defaultState, getters, mutations} from '@/plugins/store/defaultServiceState'
import Vue from "vue";
import store from "@/plugins/store";
import {MyFunctions} from "@/components/app/Utils/functions";

export default {
    namespaced: true,
    state: {
        ...defaultState
    },
    getters: {
        ...getters
    },
    mutations: {
        ...mutations
    },
    actions: {
        saveExtraDocuments({commit, dispatch}, payload) {
            const token = store.state.security.jwt;
            const product_name = store.state.waiting_status.item.current_product;
            const {shareholders = []} = payload;

            if (shareholders.length !== 0) {
                const shareholders_copy = shareholders.map(shareholder => {
                    const {full_name, phone, email, person_type} = shareholder;
                    return {person_type, full_name, email, phone: `+52${MyFunctions.cleanPhone(phone)}`};
                });
                payload.shareholders = shareholders_copy;
            }

            let formData = new FormData();
            Object.keys(payload).forEach((k) => (payload[k] == null || payload[k] == '') && delete payload[k]);
            Object.keys(payload).map(key => {
                const value = payload[key];
                if (Array.isArray(payload[key])) {
                    payload[key].map(value => {
                        Object.keys(value).forEach(v => {
                            formData.append(`${key}[][${v}]`, value[v]);
                        });

                    });
                } else {
                    formData.append(`${key}`, value);
                }

            });
            formData.append('product_id', product_name);
            commit('isSaving', true);
            commit('error', null);
            Vue.prototype.$axios.post("/api/v1/carriers/extra_documents", formData, {
                    headers: {
                        "Authorization": `${token}`,
                        'Content-Type': 'multipart/form-data'
                    }
                }
            )
                .then(function (response) {
                    commit('item', response.data.data);
                    dispatch('waiting_status/carrierStatus', token, {root: true});
                    commit('isSaving', false);
                })
                .catch(error => {
                    commit('isSaving', false);
                    const e = {type: 'error', data: error.response.data};
                    commit('app/reportMessage', e, {root: true});
                });
        },
        editExtraDocuments({commit}, payload) {
            const token = store.state.security.jwt;
            let formData = new FormData();
            Object.keys(payload).forEach((k) => (payload[k] == null || payload[k] == '') && delete payload[k]);
            Object.keys(payload).map(key => {
                formData.append(`${key}`, payload[key]);
            });
            commit('isSaving', true);
            Vue.prototype.$axios.patch("/api/v1/carriers/extra_documents", formData, {
                    headers: {
                        "Authorization": `${token}`,
                        'Content-Type': 'multipart/form-data'
                    }
                }
            )
                .then(function (response) {
                    commit('item', response.data.data);
                    commit('error', null);

                    commit('isSaving', false);
                })
                .catch(error => {
                    commit('isSaving', false);
                    commit('error', error.response.data || error.message);

                });
        },

        fetchNotGoodDocuments({commit, dispatch}) {
            const token = store.state.security.jwt;
            commit('isSaving', true);
            Vue.prototype.$axios.get("/api/v1/carriers/missing_docs", {
                    headers: {
                        "Authorization": `${token}`,
                    }
                }
            )
                .then(function (response) {
                    commit('items', response.data.data);
                    commit('error', null);
                    dispatch('waiting_status/carrierStatus', token, {root: true});
                    commit('isSaving', false);
                })
                .catch(error => {
                    commit('isSaving', false);
                    const e = {type: 'error', data: error.response.data};
                    commit('app/reportMessage', e, {root: true});
                });
        }

    }
}