import {defaultState, getters, mutations} from '@/plugins/store/defaultServiceState';
import store from "@/plugins/store";
import {MyFunctions} from "@/components/app/Utils/functions";
import Vue from "vue";
import { apiBaseURL } from '@/config/app.config';

export default {
    namespaced: true,
    state: {
        ...defaultState
    },
    getters: {
        ...getters
    },
    mutations: {
        ...mutations
    },
    actions: {
        fetchInvoicesLoads({commit}, queryParams) {
            const token = store.state.security.jwt;
            const base_url = apiBaseURL;
            const url = `${base_url}/search/payment-orders${queryParams}&hasXml=true&orderBy=createdAt`;
            commit('isLoading', true);
            commit('error', null);
            Vue.prototype.$axios(
                {
                    method: "GET",
                    url,
                    headers: {
                        "Authorization": `Bearer ${token}`,
                    }
                })
                .then(function (response) {
                    commit('items', response.data.results);
                    commit('total', response.data.total);
                    commit('isLoading', false);

                })
                .catch((error) => {
                    commit('isLoading', false);
                    const e = {type: 'error', data: error?.response?.data||error.message};
                    commit('app/reportMessage', e, {root: true});
                });
        },
        async saveInvoicesLoads({commit}, payload) {
            let endpoint_response = false;
            let formData = new FormData();
            const token = store.state.security.jwt;
            // clean object functions is usefully for remove properties that contain null, false or ''(empty)
            formData.append(`business_id`, payload['business_id']);
            formData.append(`template`, payload['template']);
            if (payload['invoices']) {
                payload['invoices'].map(v => {
                    formData.append(`invoices[]`, v);
                })
            }
            if (payload['zip']) formData.append(`zip`, payload['zip']);
            commit('isSaving', true);
            commit('error', null);
            await Vue.prototype.$axios(
                {
                    method: "POST",
                    url: "/api/v1/accounts_payable/invoices/bulk",
                    data: formData,
                    headers: {
                        "Authorization": `${token}`,
                    }
                })
                .then(function () {
                    commit('isSaving', false);
                    endpoint_response = true;
                    const e = {type: 'success', data: '¡Todo listo!, tus facturas están cargadas correctamente.'};
                    commit('app/reportMessage', e, {root: true});
                    MyFunctions.dispatchTrackEvent("invoiceBulkSuccess");
                    MyFunctions.dispatchTrackEvent("SolventoPaga.CargaMasiva.Exito");
                    commit("generalConfigs", {fetchInvoicesByDefault:true});
                })
                .catch(error => {
                    commit('isSaving', false);
                    endpoint_response = false;
                    const e = {type: 'error', data: error.response.data};
                    commit('app/reportMessage', e, {root: true});
                    MyFunctions.dispatchTrackEvent("invoiceBulkError");
                    MyFunctions.dispatchTrackEvent("SolventoPaga.CargaMasiva.Error", error.response.data);
                });
            return endpoint_response;
        },
    }
}
