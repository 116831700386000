import Vue from "vue";

import {defaultState, getters, mutations} from "@/plugins/store/defaultServiceState";

export default {
    namespaced: true,
    state: {
        ...defaultState,
    },
    getters: {
        ...getters,
    },
    mutations: {
        ...mutations,
    },
    actions: {
        async carrierStatus({ commit, rootGetters }, token) {
            const headers = { Authorization: `Bearer ${token}` };
            commit('isLoadingSingle', true);

            const isSupplier = rootGetters['security/isSupplier']

            if (isSupplier) {
              setTimeout(() => {
                commit('item', {
                  "current_product": "ACCOUNTS_PAYABLE",
                  "conversion": {
                    "current": "ADD_REFERENCES",
                    "previous_conversions": []
                  },
                  "business_info": {
                    "business_name": "",
                    "commercial_name": "",
                  },
                  "products": [
                    {
                      "id": 2,
                      "name": "ACCOUNTS_PAYABLE",
                      "status": "OFFER_ACCEPTED",
                      "conversion": {},
                    }
                  ],
                })

                commit('error', null)
                commit('isLoadingSingle', false)
              },0)

              return
            }

            try {
                 const response = await Vue.prototype.$axios({
                      method: "GET",
                      url: "/api/v1/carriers/status",
                      headers
                 });

                commit('item', response.data.data)
                commit('error', null);

            } catch (error) {
                const sanitizedError = { type: 'error', data: error.response?.data || 'Unknown error' };
                const status = error.response?.status || 1;

                if (status !== 401) {
                    commit('app/reportMessage', sanitizedError, { root: true });
                }
            } finally {
                commit('isLoadingSingle', false);
            }
        },

        accept({commit, dispatch}, payload) {
            commit("isSaving", true);
            const {token, product_id} = payload
            const headers = {Authorization: `Bearer ${token}`};
            Vue.prototype.$axios(
                {
                    method: "POST",
                    url: "/api/v1/carriers/accept",
                    data: {product_id},
                    headers

                })
                .then(function () {
                    commit('error', null);
                    commit('isSaving', false);
                    dispatch('waiting_status/carrierStatus', token, {root: true});
                })
                .catch(error => {
                    commit('isSaving', false);
                    commit('item', null);
                    const e = {type: 'error', data: error.response.data};
                    commit('app/reportMessage', e, {root: true});
                });
        }
    }
}