import { Role } from '@/shared/enums/Role'
import { MenuItem } from '@/shared/enums/MenuItem'

export default [
    {
        path: 'customers',
        name:  MenuItem.Customers,
        component: () => import('@/modules/customers/views/CustomerList.vue'),
        meta: { requireRoles: [Role.Admin, Role.FinancialManagement, Role.PlanningAuditor, Role.LegacyAccess, Role.CollabSupplier] },
    }
]
