import {MyFunctions} from "@/components/app/Utils/functions";


export default {
    namespaced: true,
    state: {
        showMenuStore: false,
        messages: [],
        breadcrumbs: [],
        overlay: false,
    },
    getters: {
        getShowMenuStore(state) {
            return state.showMenuStore;
        },
        getMessages(state) {
            return state.messages;
        },
        getBreadcrumbs(state) {
            return state.breadcrumbs;
        },
        getOverlay(state) {
            return state.overlay;
        },

    },
    mutations: {
        setShowMenuStore(state, showMenuStore) {
            state.showMenuStore = showMenuStore;
        },
        reportMessage(state, message) {

            if (message.type !== "error" || (message.type === "error" && MyFunctions.mayBeAnError(message)))
                state.messages.push(message);
        },
        confirmMessage(state, message) {
            var indice = state.messages.indexOf(message);
            state.messages.splice(indice, 1);
        },
        setBreadcrumbs(state, breadcrumbs) {
            state.breadcrumbs = breadcrumbs;
        },
        setOverlay: function (state, overlay) {
            state.overlay = overlay;
        },
        cleanMessages (state) {
            state.messages = [];
        }
    },
    actions: {
        borrarBreadcrumb: function ({commit}) {
            commit("setBreadcrumbs", []);
        },

        clearToastMessages ({commit}) {
            commit("cleanMessages")
        },
        reportMessage({commit}, message) {
            commit('reportMessage', message)
        },
        setLoading({commit}, loading) {
            commit('setOverlay', loading)
        }
    }
}