import { Role } from '@/shared/enums/Role'
import { mapGetters } from 'vuex'
import { Product as ProductType } from '@/shared/type/Product'

export default {
  name: 'PermissionsManagementMixin',
  computed: {
    ...mapGetters('security', { rolesGetter: 'getRoles'}),
    ...mapGetters('security', { hasAuditProductGetter : 'hasAuditProduct' }),
    ...mapGetters('security', { isSupplierGetter : 'isSupplier' }),
    ...mapGetters('waiting_status', ['item']),

    roles (): Role[] {
      return this.rolesGetter as unknown as Role[]
    },

    hasAuditProduct (): boolean {
      return this.hasAuditProductGetter as unknown as boolean
    },

    isSupplier (): boolean {
      return this.isSupplierGetter as unknown as boolean
    },

    isAdmin (): boolean {
      return this.roles.includes(Role.Admin)
    },

    allProducts (): ProductType[] {
      return this.item?.products || []
    },
  },
}