import { AxiosError, AxiosRequestConfig } from 'axios'
import { HttpStatus } from '@/shared/general-request/enums/HttpStatus'
import store from '@/plugins/store'
import i18n from '@/plugins/i18n/index'

export const authInterceptor = (
  config: AxiosRequestConfig,
  token: string
) => {
  if (!config.headers) {
    config.headers = {}
  }
  config.headers['Authorization'] = token
  return config
}

export const errorInterceptor = (error: AxiosError) => {
  if (error.response?.status === HttpStatus.UNAUTHORIZED) {
    store.commit('app/reportMessage', {
      type: 'error',
      data: i18n.t('Your session has expired, please log in again.')
    })
    store.dispatch('session/logout', null, { root: true }).then()
  }

  return Promise.reject(error)
}
