import {defaultState, getters, mutations} from '@/plugins/store/defaultServiceState';
import store from "@/plugins/store";
import Vue from "vue";

export default {
    namespaced: true,
    state: {
        ...defaultState
    },
    getters: {
        ...getters
    },
    mutations: {
        ...mutations
    },
    actions: {
        fetchInvoicesLoadsDetails({commit}, group_id) {
            const token = store.state.security.jwt;
            commit('isLoadingSingle', true);
            commit('error', null);
            Vue.prototype.$axios(
                {
                    method: "GET",
                    url: `/api/v1/accounts_payable/groups?group_id=${group_id}`,
                    headers: {
                        "Authorization": `${token}`,
                    }
                })
                .then(function (response) {
                    commit('item', response.data.data);
                    commit('isLoadingSingle', false);

                })
                .catch((error) => {
                    commit('isLoadingSingle', false);
                    const e = {type: 'error', data: error.response.data};
                    commit('app/reportMessage', e, {root: true});
                });
        },

    }
}