import {defaultState, getters, mutations} from '@/plugins/store/defaultServiceState';
import store from '@/plugins/store';
import {MyFunctions} from '@/components/app/Utils/functions';
import Vue from 'vue';
import {apiBaseURL} from '@/config/app.config';

export default {
    namespaced: true,
    state: {
        ...defaultState
    },
    getters: {
        ...getters
    },
    mutations: {
        ...mutations
    },
    actions: {
        async saveProviders({commit, state}, payload) {
            let formData = new FormData();
            const token = store.state.security.jwt;
            Object.keys(payload).map(key => {
                formData.append(`${key}`, payload[key]);
            });
            commit('isSaving', true);
            commit('error', null);
            return Vue.prototype.$axios(
                {
                    method: 'POST',
                    url: '/api/v1/accounts_payable/providers/bulk',
                    data: formData,
                    headers: {
                        'Authorization': `${token}`,
                    }
                })
                .then(function (response) {
                    const providers = [...state.items];
                    response.data.data.providers.forEach((provider) => {
                        const index = providers.findIndex((p) => p.id === provider.id);
                        if (index !== -1) {
                            providers[index] = provider;
                        } else {
                            providers.push(provider);
                        }
                        return null;
                    });
                    commit('items', providers);
                    commit('error', null);
                    commit('isSaving', false);
                    commit('isSaving', false);

                    const e = {type: 'success', data: '¡Todo listo!, tus proveedores están cargados correctamente.'};
                    commit('app/reportMessage', e, {root: true});
                    MyFunctions.dispatchTrackEvent('uploadProvidersSuccess');
                    MyFunctions.dispatchTrackEvent('SolventoPaga.Proveedores.Exito');
                    return response;
                })
                .catch(error => {
                    commit('isSaving', false);
                    const e = {type: 'error', data: error.response.data};
                    commit('app/reportMessage', e, {root: true});
                    MyFunctions.dispatchTrackEvent('uploadProvidersError');
                    MyFunctions.dispatchTrackEvent('SolventoPaga.Proveedores.Error', error.response.data);
                    return error;
                });
        },
        fetchProviders({commit}, query) {
            const token = store.state.security.jwt;
            commit('isLoading', true);
            commit('error', null);
            const url = `${apiBaseURL}/suppliers${query}`
            Vue.prototype.$axios(
                {
                    method: 'GET',
                    url,
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                })
                .then(function (response) {
                    commit('items', response.data.results);
                    commit('total', response.data.total);
                    commit('isLoading', false);

                })
                .catch(error => {
                    commit('isLoading', false);
                    const e = {type: 'error', data: error.response.data};
                    commit('app/reportMessage', e, {root: true});
                });
        },
    }
}