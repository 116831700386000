import Vue from 'vue';
import {defaultState, mutations, getters} from '@/plugins/store/defaultServiceState'
import router from "@/plugins/router";

export default {
    namespaced: true,
    state: {
        ...defaultState,
        isSending: false,
        sent: false,
        isValidating: false,
        verified: false
    },
    mutations: {
        ...mutations,
        isSending(state, val) {
            state.isSending = val;
        },
        setSent(state, val) {
            state.sent = val;
        },
        isValidating(state, val) {
            state.isValidating = val;
        },
        isVerified(state, val) {
            state.verified = val;
        },
    },
    getters: {
        ...getters,
        isSending(state) {
            return state.isSending;
        },
        sent(state) {
            return state.sent;
        },
        isValidating(state) {
            return state.isValidating;
        },
        isVerified(state) {
            return state.verified;
        },
    },
    actions: {
        verifyToken: ({commit}, token) => {
            commit('isSaving', true);
            Vue.prototype.$axios.post('/api/v1/users/verification/email', {
                token
            }).then(function (response) {
                commit('isSaving', false);
                commit('item', response.data);

                commit('security/setJwt', response.data.data.token, {root: true});
                const email = response.data.data.email;
                commit('session/user', {email}, {root: true});
                commit('session/isAuthenticating', false, {root: true});
                commit('session/sessionStarted', true, {root: true});
                commit('error', null);
                Vue.prototype.$session.set("user", {email});
                Vue.prototype.$FS.identify(email, {
                    displayName: email,
                    email: email,
                    reviewsWritten_int: 14
                });

                router.push({name: 'VALIDATE_PHONE'});
            }).catch(() => {
                commit('error', 'Algo salió mal durante la verificación');
                commit('isSaving', false);
            });
        },
        validate_on_register({commit}, email) {
            commit('error', null);
            commit('isSending', true);
            commit('setSent', false);
            Vue.prototype.$axios(
                {
                    method: "POST",
                    url: "/api/v1/users/email_validation",
                    data: {email},
                }).then(function (response) {
                commit('item', response.data.data);
                commit('isSending', false);
                commit('setSent', true);

            }).catch(error => {
                commit('isSending', false);
                commit('setSent', false);
                const e = {type: 'error', data: error.response.data};
                commit('app/reportMessage', e, {root: true});
            });
        },
        send_validation({commit}, payload) {
            const {email, code} = payload;
            commit('error', null);
            commit('isValidating', true);
            commit('isVerified', false);
            Vue.prototype.$axios(
                {
                    method: "POST",
                    url: "/api/v1/validations/email",
                    data: {email, code},
                }).then(function (response) {

                commit('item', response.data.data);

                commit('isValidating', false);
                commit('isVerified', true);
            })
                .catch(error => {
                    commit('isValidating', false);
                    commit('isVerified', false);
                    const e = {type: 'error', data: error.response.data};
                    commit('app/reportMessage', e, {root: true});
                });
        }
    },

}