import loadsFilesAnalyzerStoreManager
  from '@/shared/components/loading-loads-files-analyzer/store/loadsFilesAnalyzerStoreManager';
import {File} from '@/shared/components/loading-loads-files-analyzer/interfaces/File';
import appStoreManager from '@/shared/store/appStoreManager';
import {Message} from '@/shared/interfaces/Message';

export default {
  computed: {
    showLoadsFilesAnalyzer() {
      return loadsFilesAnalyzerStoreManager.showLoadsFilesAnalyzer()
    },
  },
  methods: {
    toggleShowLoadsFilesAnalyzer(show: boolean): void {
      loadsFilesAnalyzerStoreManager.toggleShowLoadsFilesAnalyzer(show)
    },
    setFilesFromLoadsFilesAnalyzer(files: File[]): void {
      loadsFilesAnalyzerStoreManager.setFiles(files)
    },
    addFileToLoadsFilesAnalyzer(file: File): void {
      loadsFilesAnalyzerStoreManager.addFile(file)
    },
    clearFilesFromLoadsFilesAnalyzer(): void {
      loadsFilesAnalyzerStoreManager.clearFiles()
    },
    setLoading(loading: boolean): void {
      appStoreManager.setLoading(loading)
    },
    reportMessage(message: Message): void {
      appStoreManager.reportMessage(message)
    },
    clearFilesAndClose(): void {
      this.clearFilesFromLoadsFilesAnalyzer()
      this.toggleShowLoadsFilesAnalyzer(false)
    }
  }
}
