import { httpClientV1 } from '@/shared/general-request'
import { Credentials } from '@/modules/login/interfaces/Credentials'

const authRequestClient = {
  authCarrier: (credentials: Credentials) => {
    return httpClientV1.post('/users/login', credentials)
  },

  logoutCarrier: (refreshToken: unknown) => {
    return httpClientV1.post('/auth/logout', { refreshToken })
  },

  authSupplier: (credentials: Credentials) => {
    return httpClientV1.post('/suppliers/login', credentials)
  },

  logoutSupplier: (refreshToken: unknown) => {
    return httpClientV1.post('/suppliers/logout', { refreshToken })
  },

  refreshTokenCarrier: (refreshToken: unknown) => {
    return httpClientV1.post('/auth/refresh', {refreshToken})
  },

  refreshTokenSupplier: (refreshToken: unknown) => {
    return httpClientV1.post('/suppliers/refresh', {refreshToken})
  }
}

export default authRequestClient