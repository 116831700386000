import {defaultState, getters, mutations} from '@/plugins/store/defaultServiceState'
import store from "@/plugins/store";
import Vue from "vue";

export default {
    namespaced: true,
    state: {
        ...defaultState
    },
    getters: {
        ...getters
    },
    mutations: {
        ...mutations
    },
    actions: {
        saveCiec({commit}, payload) {
            const token = store.state.security.jwt;
            commit('isSaving', true);
            Vue.prototype.$axios(
                {
                    method: "POST",
                    url: "/api/v1/carriers/business_info_sat",
                    data: {...payload},
                    headers: {
                        "Authorization": `${token}`,
                    }
                })
                .then(function (response) {
                    commit('item', response.data.data);
                    commit('error', null);
                    commit('isSaving', false);
                })
                .catch(error => {
                    commit('isSaving', false);
                    const e = {type: 'error', data: error.response.data};
                    commit('app/reportMessage', e, {root: true});
                });
        },

        updateCiec({commit}) {
            const url = 'https://62505b0fe3e5d24b3420ddbb.mockapi.io/ciec_info/1';
            Vue.prototype.$axios(
                {
                    method: "GET",
                    url,
                })
                .then(function (response) {
                    // commit('item', response.data.data);
                    commit('item', response.data);
                    commit('error', null);
                    commit('isSaving', false);
                })
                .catch(error => {
                    commit('isSaving', false);
                    const e = {type: 'error', data: error.response.data};
                    commit('app/reportMessage', e, {root: true});
                });
        }
    },

}