import VueJwtDecode from 'vue-jwt-decode'
import axiosPrototype from '@/plugins/axios';
import router from '@/plugins/router';
import Vue from "vue";
import MENU_ITEMS_BY_ROLES from "@/shared/const/MenuItemsByRoles";
import { Role } from '@/shared/enums/Role'
import authRequestClient from '@/modules/login/api-client/AuthRequestClient'

const AVAILABLE_ROLES = Object.keys(MENU_ITEMS_BY_ROLES);

const DEFAULT_ROLE = 'legacy_access';

const TOKEN_REFRESH_DELAY_SECONDS = 12;

const extractRoles = (state, jwt) => {
    const decodedAccessToken = VueJwtDecode.decode(jwt)
    const rolesFromApi = decodedAccessToken?.realm_access?.roles ?? [];
    const roles = rolesFromApi.filter(role => AVAILABLE_ROLES.includes(role));

    if (!roles.length) {
        roles.push(DEFAULT_ROLE)
    }
    state.roles = roles;
    state.isSupplier = state.roles.includes(Role.Supplier)
}
export default {
    namespaced: true,
    state: {
        jwt: null,
        refreshToken: null,
        roles: [],
        hasAuditProduct: false,
        isSupplier: false,
    },
    getters: {
        getJwt(state) {
            return state.jwt;
        },
        getRoles(state) {
            return state.roles;
        },
        getRefreshToken(state) {
            return state.refreshToken;
        },
        getUserId(state) {
            if (state.jwt) {
                return state.isSupplier
                  ? VueJwtDecode.decode(state.jwt).sid || null
                  : VueJwtDecode.decode(state.jwt).id || null
            }
            return null
        },
        getEmail(state) {
            if (state.jwt) {
                return VueJwtDecode.decode(state.jwt).email || null
            }
            return null
        },
        getCarrierId(state) {
            if (state.jwt) {
                return VueJwtDecode.decode(state.jwt).carrier_id || null
            }
            return null
        },
        getSupplierTaxId(state) {
            if (state.jwt) {
                return VueJwtDecode.decode(state.jwt).taxId || null
            }
            return null
        },
        hasAuditProduct (state) {
            return state.hasAuditProduct;
        },
        isSupplier (state) {
            return state.isSupplier
        },
    },
    mutations: {
        setJwt(state, jwt) {
            state.jwt = jwt;

            axiosPrototype.setHeader(jwt)
            if (!jwt) {
                return
            }
            extractRoles(state, jwt)
        },
        setRefreshToken(state, refreshToken) {
            state.refreshToken = refreshToken;
        },
        RESTORE_STATE(state, newState) {
            Object.assign(state, newState);
        },
        setHasAuditProduct(state, hasAuditProduct) {
            state.hasAuditProduct = hasAuditProduct;
        },
    },
    actions: {
        verifyJwt: ({commit, getters, dispatch}, alternativePath = "/register") => {
            if (getters.getJwt) {
                let decodedJwt = VueJwtDecode.decode(getters.getJwt);
                const now = Math.floor(Date.now() / 1000);
                const gapBetweenRefreshTimeAndNow = decodedJwt.exp - now;
                const needRefresh = gapBetweenRefreshTimeAndNow < TOKEN_REFRESH_DELAY_SECONDS;
                if (needRefresh) dispatch("refreshToken");
            } else {
                commit('setJwt', null);
                commit('setRefreshToken', null);
                router.push(alternativePath)
                    .catch(() => {
                    });
            }

        },
        refreshToken: async ({commit, state, dispatch}) => {
            const {refreshToken} = state;
            if (!refreshToken) {
                dispatch("session/logout", null, {root: true});
            }
            try {
                const response = state.isSupplier
                  ? await authRequestClient.refreshTokenSupplier(refreshToken)
                  : await authRequestClient.refreshTokenCarrier(refreshToken)
                const { token } = response.data
                commit("setJwt", token)
            } catch (e) {
                dispatch("session/logout", null, {root: true});
                console.log(e);
            }
        },
    },
}