import i18n from '@/plugins/i18n';
import { Role } from '@/shared/enums/Role'
import { MenuItem } from '@/shared/enums/MenuItem'
export default [
    {
        path: 'loads',
        name: MenuItem.Loads,
        component: () => import('@/modules/loads-deliveries/views/LoadsAndDeliveries.vue'),
        redirect: { name: MenuItem.LoadsList },
        meta: {
            breadcrumb: i18n.t('Loads and deliveries'),
            requireRoles: [Role.Admin, Role.FinancialManagement, Role.PlanningAuditor, Role.LegacyAccess, Role.Supplier],
        },
        children: [
            {
                path: 'list',
                name: MenuItem.LoadsList,
                component: () => import('@/modules/loads-deliveries/views/loads-list/LoadsList.vue'),
                meta: {
                    breadcrumb: i18n.t('Loads and deliveries'),
                    requireRoles: [Role.Admin, Role.FinancialManagement, Role.PlanningAuditor, Role.LegacyAccess, Role.Supplier],
                },
            },
            {
                path: ':loadId/detail',
                name: MenuItem.LoadDetail,
                component: () => import('@/modules/loads-deliveries/views/load-detail/LoadDetail.vue'),
                meta: {
                    breadcrumb: i18n.t('Load'),
                    requireRoles: [Role.Admin, Role.FinancialManagement, Role.PlanningAuditor, Role.LegacyAccess, Role.Supplier],
                },

            }
        ]
    }
]
