import { Role } from '@/shared/enums/Role'
import { MenuItem } from '@/shared/enums/MenuItem'

export default [
    {
        path: 'invoices',
        name: MenuItem.Invoices,
        component: () => import('@/modules/invoices/views/InvoicesList.vue'),
        meta: { requireRoles: [Role.Admin, Role.FinancialManagement, Role.PlanningAuditor, Role.LegacyAccess] },
    }
]
