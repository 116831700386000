import {defaultState, getters, mutations} from '@/plugins/store/defaultServiceState'
import store from "@/plugins/store";
import Vue from "vue";

export default {
    namespaced: true,
    state: {
        ...defaultState
    },
    getters: {
        ...getters
    },
    mutations: {
        ...mutations
    },
    actions: {
        fetchFiles({commit}, payload) {

            const {path, option} = payload;
            const token = store.state.security.jwt;
            commit('isLoading', true);
            return Vue.prototype.$axios({
                url: `/api/v1/files?file_name=${path}`, //your url
                method: 'GET',
                responseType: 'blob', // important
                headers: {
                    "Authorization": `${token}`,
                }
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data], {type: [response.data][0].type}));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(`${option == 'see' ? 'target' : 'download'}`, `${option == 'see' ? '_blank' : path}`); //or any other extension
                document.body.appendChild(link);
                link.click();
                commit('isLoading', false);
                commit('error', null);
            }).catch((error) => {
                commit('isLoading', false);
                const e = {type: 'error', data: error.response.data};
                commit('app/reportMessage', e, {root: true});
            });
        }
    }
}