import { MyFunctions } from '@/components/app/Utils/functions'
import { fetchProductInfoByBusinessId } from '@/shared/api-client/AccountsPayableProductClient';
import { Product } from '@/shared/enums/Product'

const validateHasAuditProduct = async () => {
    const product = MyFunctions.find_product_by_name(Product.AccountsPayable);

    if (!product) {
        return false;
    }
    const { track_id } = product;

    try {
        const response = await fetchProductInfoByBusinessId(track_id);
        const {business = null} = response?.data?.data;
        const hasAudit = business
            ? business[0]?.products.includes('AUDITA')
            : false;
        return !!hasAudit
    } catch (error) {
        return false;
    }
}

export default validateHasAuditProduct