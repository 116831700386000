import {defaultState, getters, mutations} from '@/plugins/store/defaultServiceState'

export default {
    namespaced: true,
    state: {
        ...defaultState
    },
    getters: {
        ...getters
    },
    mutations: {
        ...mutations
    },
    actions: {
        createInstance({commit}, Pusher) {
            commit('item', Pusher);
        },
        closeConnection({commit, state}) {
            if (state.item) state.item.disconnect();
            commit('item', null);
        },
    }
}