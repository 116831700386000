import {defaultState, getters, mutations} from '@/plugins/store/defaultServiceState'
import store from "@/plugins/store";
import { apiBaseURL } from '@/config/app.config';
import Vue from "vue";

export default {
    namespaced: true,
    state: {
        ...defaultState
    },
    getters: {
        ...getters
    },
    mutations: {
        ...mutations
    },
    actions: {
        fetchCredits({commit}, paramUrl = null) {
            const token = store.state.security.jwt;
            const base_url = apiBaseURL;
            const url = `${base_url}${paramUrl}`;

            commit('isLoading', true);
            commit('error', null);
            commit('items', []);
            commit('total', 0);

            Vue.prototype.$axios(
                {
                    method: "GET",
                    url,
                    headers: {
                        "Authorization": `Bearer ${token}`,
                    }
                })
                .then(function (response) {
                    commit('items', response.data.results);
                    commit('total', response.data.total);
                    commit('error', null);
                    commit('isLoading', false);
                })
                .catch(() => {
                    commit('isLoading', false);
                    const e = {
                        type: 'error',
                        data: '¡Ups!, hubo un error al intentar conseguir información de créditos'
                    };
                    commit('app/reportMessage', e, {root: true});
                });

        },
    }
}
