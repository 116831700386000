export enum MenuItem {
  Welcome = 'welcome',
  Invoices = 'invoices',
  AccountsPayable = 'accounts-payable',
  Loads = 'loads',
  LoadsList = 'loads-list',
  LoadDetail = 'load-detail',
  Suppliers = 'suppliers',
  Benefits = 'benefits',
  Tariff = 'tariff',
  Metrics = 'metrics',
  Customers = 'customers',
  Settings = 'settings',
  CobraCollab = 'cobra-collab',
  CarrierMetrics = 'carrier-metrics',
  FinancialMetrics = 'financial-metrics',
}