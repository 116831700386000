import {defaultState, getters, mutations} from '@/plugins/store/defaultServiceState'
import Vue from "vue";

import store from "@/plugins/store";
import { apiBaseURL } from '@/config/app.config';

export default {
    namespaced: true,
    state: {
        ...defaultState
    },
    getters: {
        ...getters
    },
    mutations: {
        ...mutations
    },
    actions: {
        fetchInvoices({commit}, queryParams = null) {
            const token = store.state.security.jwt;
            const base_url = apiBaseURL;
            const url = `${base_url}${queryParams}`;

            commit('isLoading', true);
            commit('error', null);
            commit('items', []);

            Vue.prototype.$axios(
                {
                    method: "GET",
                    url,
                    headers: {
                        "Authorization": `Bearer ${token}`,
                    }
                })
                .then(function (response) {
                    commit('items', response.data.results);
                    commit('total', response.data.total);
                    commit('error', null);
                    commit('isLoading', false);
                })
                .catch(() => {
                    commit('isLoading', false);
                    const e = {
                        type: 'error',
                        data: '¡Ups!, hubo un error al intentar conseguir información de créditos'
                    };
                    commit('app/reportMessage', e, {root: true});
                });


        },

        downloadDetails: function ({commit}, payload) {
            const token = store.state.security.jwt;
            const base_url = apiBaseURL;
            const {query, fileName} = payload;
            const url = `${base_url}${query}`;

            commit('isLoadingSingle', true);
            Vue.prototype.$axios(
                {
                    method: "GET",
                    url,
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Accept": "text/csv",
                        "Accept-Language": "es"
                    }
                })
                .then(function (response) {
                    commit('isLoadingSingle', false);

                    const url = window.URL.createObjectURL(new Blob([response.data], {type: response.headers["content-type"]}));

                    const link = document.createElement('a');

                    link.href = url;
                    link.setAttribute(`download`, fileName);
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(() => {
                    commit('isLoadingSingle', false);
                    const e = {
                        type: 'error',
                        data: '¡Ups!, hubo un error al intentar descargar los detalles'
                    };
                    commit('app/reportMessage', e, {root: true});
                });

        }
    }
}
