<template>
  <v-app>
    <router-view />
  </v-app>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { MyFunctions } from '@/components/app/Utils/functions'
import PusherMixin from '@/shared/mixins/PusherMixin'
import {
  STATUS_EVENT,
  subscribeToChannel,
  unsubscribeAllChannels,
} from '@/shared/notifications/pusher-config'
import PermissionsManagementMixin from '@/shared/mixins/PermissionsManagementMixin'
import MENU_ITEMS_BY_ROLES from '@/shared/const/MenuItemsByRoles'
import { MenuItem } from '@/shared/enums/MenuItem'
import { nextTick } from 'vue'

const VERIFY_TOKEN_TIME_INTERVAL = 5000
export default {
  name: 'App',
  mixins: [PusherMixin, PermissionsManagementMixin],
  data: () => {
    return {
      FETCH_INVOICES_SAT_REQUEST_DELAY: 60000,
      identifyIntervalVerifyToken: null,
    }
  },
  computed: {
    ...mapGetters('waiting_status', ['item']),
    ...mapGetters('feature_flag', { feature_flag_item: 'item' }),
    ...mapGetters('security', ['getJwt']),
    ...mapGetters('security', {
      token: 'getJwt',
      userId: 'getUserId',
      supplierTaxId: 'getSupplierTaxId',
    }),
    ...mapGetters('extraction_info', { extractionInfoItem: 'item' }),
    ...mapGetters('pusher', { pusherItem: 'item' }),

    conversion: function () {
      if (this.item) {
        const carrier_conversion = this.item.conversion
        const { current_product, products = [] } = this.item
        const product = products.find((p) => p.name === current_product)
        if (!carrier_conversion) return null
        if (product)
          return Object.keys(product.conversion).length === 0
            ? 'EMPTY'
            : product.conversion.current
        if (!product && carrier_conversion)
          return Object.keys(carrier_conversion).length === 0
            ? 'EMPTY'
            : carrier_conversion.current
        return null
      }
      return false
    },

    entity_type: function () {
      if (this.item) {
        return this.item?.business_info?.entity_type || false
      }
      return false
    },

    status: function () {
      if (this.item) return this.item.status
      return false
    },

    inMaintenance: function () {
      if (this.feature_flag_item) return this.feature_flag_item.maintenance
      return null
    },

    businessName: function () {
      return MyFunctions.get_business_name()
    },

    channelId() {
      return this.isSupplier ? this.supplierTaxId : this.userId
    },
  },
  watch: {
    conversion: function (value) {
      setTimeout(() => {
        nextTick(() => {
          this.handleConversionChange(value)
        })
      }, 1000)
    },
    token: function (newToken, oldToken) {
      if (!newToken) {
        unsubscribeAllChannels()
        return
      }

      if (!this.channelId) {
        return
      }
      this.subscribeToChannelWhenRefreshPage()

      this.clearVerifyTokenPeriodically()
    },
    inMaintenance: function (val) {
      if (val && this.$router.currentRoute.name !== 'maintenance') {
        try {
          this.$session.set(
            'recover_path_after_maintenance',
            this.$router.currentRoute.fullPath
          )
          this.$router.push({ name: 'maintenance' }).catch(() => {})
        } catch (e) {
          console.log(e)
        }
      } else if (
        val === false &&
        this.$router.currentRoute.name === 'maintenance'
      ) {
        // TODO investigate how can go back after out of maintenance page (this.$router.go(-1);) is not working if page was refreshed
        let routeTo = this.$session.get('recover_path_after_maintenance')
        if (routeTo === '/') {
          routeTo += 'welcome'
          this.$router.push(routeTo)
        } else {
          this.$router.go(-1)
        }
      }
    },
    businessName: function (businessName) {
      if (businessName) {
        MyFunctions.setPersonalInfoDnDataAnalysisSites({ $name: businessName })
      }
    },
  },
  async mounted() {
    try {
      await this.fetchFeatureFlagsEnv()
    } catch (e) {
      console.log('e', e)
    }
    if (this.getJwt) {
      this.subscribeToChannelWhenRefreshPage()
      await this.carrierStatus(this.getJwt)
    }
    this.identifyIntervalVerifyToken = this.verifyTokenPeriodically()
  },
  methods: {
    ...mapActions('feature_flag', ['fetchFeatureFlagsEnv']),
    ...mapActions('waiting_status', ['carrierStatus']),
    ...mapActions('security', ['verifyJwt']),
    ...mapMutations('ciec', { setSavingCiec: 'isSaving' }),
    ...mapActions('extraction_info', ['fetchCiecInfo']),
    ...mapActions('pusher', ['createInstance']),
    ...mapMutations('extraction_info', { setExtractionInfoNeeded: 'item' }),
    ...mapMutations('app', ['reportMessage']),
    ...mapMutations('invoices_sat', ['generalConfigs']),

    handleConversionChange(value) {
      //TODO this execution is not working as expected, it should be refactored depends on the query of waiting_status
      if (value && value !== 'ADD_IDENTIFICATION')
        localStorage.removeItem('interviewId')
      if (
        this.inMaintenance &&
        this.$router.currentRoute.name !== 'maintenance'
      ) {
        try {
          this.$router.push({ name: 'maintenance' })
        } catch (e) {
          console.log(e)
        }
        return null
      } else if (this.inMaintenance) {
        return null
      }
      const stepperConversions = [
        'ADD_BUSINESS_INFO',
        'ADD_CIEC',
        'ADD_REFERENCES',
        'ADD_IDENTIFICATION',
        'ADD_EXTRA_DOCUMENTS',
      ]
      const welcomePageConversions = [
        'WAIT_FOR_APPROVAL',
        'NOTIFY_CARRIER_DECLINED',
      ]
      try {
        const menu_urls = Object.values(MenuItem)
        if (value) {
          window.scrollTo(0, 0)
          if (value === 'EMPTY' || welcomePageConversions.includes(value)) {
            if (menu_urls.includes(this.$router.currentRoute.name)) {
              return
            }

            const is_not_on_welcome_page =
              this.$router.currentRoute.name !== MenuItem.Welcome

            if (is_not_on_welcome_page) {
              const mainMenuOption = MENU_ITEMS_BY_ROLES[this.roles[0]][0]
              this.$router.push({ name: mainMenuOption })
            }
          }

          if (stepperConversions.includes(value)) {
            if (this.$router.currentRoute.name !== 'onboarding2') {
              nextTick(() => {
                this.$router.push({ name: 'onboarding2' })
              })
            } else {
              return
            }
          }
          if (value !== 'EMPTY') {
            return this.$router.push({ name: value })
          }
        }
      } catch (e) {
        console.log(e)
      }
    },

    subscribeToChannelWhenRefreshPage() {
      if (!this.channelId) {
        return
      }
      subscribeToChannel(this.channelId)
      this.subscribeAndHandleEvent(STATUS_EVENT, () => {
        this.carrierStatus(this.token)
      })
    },

    verifyTokenPeriodically() {
      return setInterval(() => {
        if (this.getJwt) this.verifyJwt()
      }, VERIFY_TOKEN_TIME_INTERVAL)
    },
    clearVerifyTokenPeriodically() {
      clearInterval(this.identifyIntervalVerifyToken)
    },
  },
}
</script>

<style>
@import './assets/style/main.scss';

.row-pointer tbody tr :hover {
  cursor: pointer;
}
</style>
