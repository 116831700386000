
export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('@/modules/login/AuthLayout.vue'),
        meta: { guest: true },
        redirect: { name: 'login-carriers' },
        children: [
            {
                path: 'carriers',
                name: 'login-carriers',
                component: () => import('@/modules/login/views/CarriersAuth.vue'),
                meta: { guest: true },
            },
            {
                path: 'suppliers',
                name: 'login-suppliers',
                component: () => import('@/modules/login/views/SuppliersAuth.vue'),
                meta: { guest: true },
            },
        ],
    },
]


