import Vue from 'vue'
import Cookies from 'js-cookie'
import './shared/filters'
import './ui-lib/directives'
import './config/plugins'

import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from '@/plugins/router'
import store from '@/plugins/store/index'
import axios from '@/plugins/axios'
import message from '@/components/app/classes/Message'
import i18n from '@/plugins/i18n'
import VueSessionStorage from 'vue-sessionstorage';
import * as FullStory from '@fullstory/browser';
import VueLuzmoDashboard from '@luzmo/vue-embed/vue2';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import Donut from 'vue-css-donut-chart';
import 'vue-css-donut-chart/dist/vcdonut.css';
import VueMask from 'v-mask'
import Hotjar from '@hotjar/browser';
import mixpanel from 'mixpanel-browser';
import { hotjarSiteId, hotjarSiteVersion, mixpanelSiteId, cookieName } from '@/config/app.config';
import * as configcat from 'configcat-js';
import globalMethodsMixin from '@/shared/mixins/globalMethodsMixin.ts';


if(localStorage.getItem('vuex')!==null) localStorage.removeItem('vuex');
const persistedState = Cookies.get(cookieName);

if (persistedState) {
    try {
        const parsedState = JSON.parse(persistedState);
        store.commit('security/RESTORE_STATE', parsedState);
    } catch (e) {
        console.error('Error al parsear el estado guardado:', e);
    }
}


Vue.use(VueMask)
Vue.use(Donut);

Vue.use(VueSessionStorage);

Vue.prototype.$axios = axios;
Vue.prototype.$Message = message;
Vue.mixin(globalMethodsMixin);
Vue.config.productionTip = false
axios.init();

store.dispatch('security/verifyJwt', () => {/*TODO ADD INITIAL BEHAVIOR */
});

FullStory.init({orgId: '17WVJB'});
Vue.prototype.$FS = FullStory;
Vue.prototype.$configCat = configcat;

if (hotjarSiteId)
Hotjar.init(hotjarSiteId, hotjarSiteVersion);
Vue.prototype.Hotjar = Hotjar;


if (mixpanelSiteId)
    mixpanel.init(mixpanelSiteId, {track_pageview: true});
Vue.prototype.mixpanel = mixpanel;

Vue.use(VueLuzmoDashboard);

new Vue({
    vuetify, router, store: store, i18n, data: {fontSize: 1},
    render: h => h(App),
}).$mount('#app')
