import axios from 'axios';
import Vue from 'vue';
import store from '@/plugins/store/index'
import i18n from '@/plugins/i18n/index';

// const jwt = require('jsonwebtoken');

export default {
    axiosInstance: null,
    requestInterceptor: config => {
        //TODO: ADD VERIFICATION SESSION
        return config;
    },
    responseInterceptor: response => {
        return response;
    },
    responseErrorInterceptor: error => {
        // CHECK IF IS INVALID_AUTH_TOKEN
        const IS_INVALID_AUTH_TOKEN = error.request.status === 401 && error.response?.data?.code !== 'INVALID_CREDENTIALS'
        const invalidTokenError = {
            type: 'error',
            data: i18n.t("EXCEPTIONS.GENERAL.INVALID_AUTH_TOKEN_MESSAGE")
        };

        if (IS_INVALID_AUTH_TOKEN){
            store.commit("app/reportMessage", invalidTokenError);
            store.dispatch("session/logout", null, {root: true});
        }


        return Promise.reject(error);
    },
    setHeader(token) {
        this.axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    },
    init() {
        const token = store.state.security.jwt;
        this.axiosInstance = axios.create({});
        this.axiosInstance.interceptors.request.use(this.requestInterceptor);
        this.axiosInstance.interceptors.response.use(this.responseInterceptor, this.responseErrorInterceptor);
        this.setHeader(token);
        Vue.prototype.$axios = this.axiosInstance;
    }
};