import {defaultState, getters, mutations} from "@/plugins/store/defaultServiceState";
import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        ...defaultState,
    },
    getters: {
        ...getters
    },
    mutations: {
        ...mutations,
    },
    actions: {
        async GetDocuments({commit}) {
            commit('isLoading', true);

            await Vue.prototype.$axios.get('/api/v1/entities_kyc')
                .then(async function (response) {

                    commit('items',
                        response.data.data.kycs.filter(kyc_document => {
                            return kyc_document.kyc_name !== 'SAT_INFO' ?
                                kyc_document.kyc_name !== 'CREDIT_HISTORY' ?
                                    kyc_document.kyc_name !== 'BLACK_LIST' ?
                                        kyc_document
                                        : ''
                                    : ''
                                : ''
                        })
                    );
                    commit('isLoading', false);
                })
                .catch((error) => {
                    commit('isLoading', false);
                    commit('error', error?.response?.data?.code || error.message)
                });
        },
        async UploadFiles({commit, state}, params) {
            commit('isLoading', true);

            await Vue.prototype.$axios.post('/api/v1/carriers/kyc_documents', params)
                .then(async function (response) {
                    let foundIndex = state.items.findIndex(item => item.kyc_name === response.data.data.kyc_name)
                    state.items[foundIndex] = response.data.data
                    let element = [...state.items]
                    commit('items', element);
                    commit('isLoading', false);
                    const e =
                        {
                            type: 'success',
                            data: '¡Gracias!, tu documento está cargado y será revisado por el equipo de Solvento.'
                        };
                    commit('app/reportMessage', e, {root: true});
                })
                .catch((error) => {
                    commit('isLoading', false);
                    commit('error', error?.response?.data?.code || error.message)
                });
        }
    }
}