import type { RequestParams } from '../interfaces/RequestParams'
import { apiBaseURL, workflowServiceUrl, bulkServiceUrl } from '@/config/app.config'

export const getRequestParams = (): RequestParams => {

  return {
    apiV1: {
      url: '/api/v1',
    },
    apiV2: {
      url: apiBaseURL || '',
    },
    workflow: {
      url: workflowServiceUrl || '',
    },
    bulkService: {
      url: bulkServiceUrl || '',
    },
  }
}
