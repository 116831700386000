const defaultState = {
    user: {email: ''},
    isAuthenticating: null,
    sessionStarted: false,
    error: null,
    loginRetries: 0,
}

const getters = {
    user(state) {
        return state.user;
    },
    isAuthenticating(state) {
        return state.isAuthenticating;
    },
    loginRetries(state) {
        return state.loginRetries;
    },
    sessionStarted(state) {
        return state.sessionStarted;
    },
    error(state) {
        return state.error;
    },
}
const mutations = {
    user(state, user) {
        state.user = user;
    },
    isAuthenticating(state, isAuthenticating) {
        state.isAuthenticating = isAuthenticating;
    },
    loginRetries(state, loginRetries) {
        state.loginRetries = loginRetries;
    },
    sessionStarted(state, sessionStarted) {
        state.sessionStarted = sessionStarted;
    },
    error(state, error) {
        state.error = error;
    },

    clearState(state) {
        state.user = null;
        state.isAuthenticating = false;
        state.sessionStarted = false;
        state.error = null;
        state.loginRetries = 0;
    }
}

module.exports = {defaultState, getters, mutations};