import store from "@/plugins/store";
import {defaultState, getters, mutations} from "@/plugins/store/defaultServiceState";
import Vue from 'vue';


export default {
    namespaced: true,
    state: {
        ...defaultState,
    },
    getters: {
        ...getters
    },
    mutations: {
        ...mutations
    },
    actions: {
        getToken() {
            const isAuth = store.state.security.jwt != null;
            return isAuth;
        },
        fetchProducts({commit}) {
            const token = store.state.security.jwt;
            Vue.prototype.$axios({
                method: "GET",
                url: "/api/v1/carriers/products",
                headers: {
                    Authorization: token,
                }
            })
                .then(async function (response) {
                    commit('error', null);
                    commit('isLoadingSingle', false);
                    commit('items', response.data.data);
                })
                .catch((error) => {
                    commit('isLoadingSingle', false);
                    commit('error', error?.response?.data?.code || error.message)
                });
        },
        async fetchAllProducts({commit}) {
            await Vue.prototype.$axios.get('api/v1/carriers/all_products')
                .then(async function (response) {
                    if (response.data.status === 'success') {
                        commit('items', response.data.data);
                    }
                    commit('isLoading', false);
                })
                .catch((error) => {
                    commit('isLoading', false);
                    commit('error', error?.response?.data?.code || error.message);
                });
        },
        async patchProduct({commit}, params) {

            const token = store.state.security.jwt;
            commit('isSaving', true);
            const response = await this.$axios.patch("/api/v1/carriers/data", params, {
                    headers: {
                        "Authorization": `${token}`,
                    }
                }
            );
            return response;
        },
    }
}