import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import icons from '@/plugins/vuetify/icons'
import 'font-awesome/css/font-awesome.min.css'
import '@mdi/font/css/materialdesignicons.css'
import es from 'vuetify/es5/locale/es'
import en from 'vuetify/es5/locale/en'

Vue.use(Vuetify);

const opts = {
    icons: icons,
    theme: {
        default: 'light',
        themes: {
            light: {
                // primary: '#00AEF1',
                primary: '#1fabe2',
                secondary: '#324956',
                accent: '#82B1FF',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FB8C00',
            },
            dark: {
                primary: '#2196F3',
                secondary: '#424242',
                accent: '#FF4081',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FB8C00',
            },
        }
    },
    lang: {
        locales: {en, es},
        current: 'es',
    },
};

export default new Vuetify(opts);
