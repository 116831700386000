import claves_lada_solo from "@/components/app/Utils/claves_lada_solo";
import store from '@/plugins/store/index';
import i18n from '@/plugins/i18n';
import Vue from 'vue';
import { hotjarSiteId, mixpanelSiteId } from '@/config/app.config';
import { ProductStatus } from '@/shared/enums/ProductStatus'
import { Product } from '@/shared/enums/Product'


const _ = require('lodash');

export const MyFunctions = {
    dispatchSurveyEvent: function (eventName) {
        if (hotjarSiteId) Vue.prototype.Hotjar.event(eventName);
    },
    errorDataToi18n: function (error_data) {
        if (!error_data || !error_data?.code || !error_data?.data) return null;
        const i18n_error_data = _.cloneDeep(error_data);
        i18n_error_data.code = i18n.t(`EXCEPTIONS.CODES.${i18n_error_data.code}`);
        i18n_error_data.data.errors = error_data.data.errors.map(err => {
            const {params, message} = err;
            return {params: this.processesParams(params), message: this.processesMessages(message)};
        });
        return i18n_error_data;
    },
    dispatchTrackEvent: function (eventName, error_data) {
        const i18n_error_data = this.errorDataToi18n(error_data);
        if (mixpanelSiteId) Vue.prototype.mixpanel.track(eventName, i18n_error_data);
        this.dispatchSurveyEvent(eventName);
    },
    identifyDnDataAnalysisSites: function (id, data = {}) {
        if (hotjarSiteId)
            Vue.prototype.Hotjar.identify(id, data);
        Vue.prototype.$FS.identify(id, data);
        if (mixpanelSiteId)
            Vue.prototype.mixpanel.identify(id, data);
    },
    setPersonalInfoDnDataAnalysisSites: function (data) {
        if (mixpanelSiteId)
            Vue.prototype.mixpanel.people.set(data);
    },
    checkLength: function (cad) {
        return cad.length >= 8 && cad.length <= 16;
    },
    checkUpperLowerCase: function (cad) {
        return /(?=.*[a-z])(?=.*[A-Z])/.test(cad) || false;
    },
    checkDigit: function (cad) {
        return /(?=.*?[0-9])/.test(cad) || false;
    },
    checkSymbols: function (cad) {
        return /(?=.*\W)/.test(cad) || false;
    },
    cleanPhone: function (phone) {
        if (!phone) return '';
        return phone.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '').replaceAll(' ', '');
    },
    format_to_claveLada2Digits: function (phone) {
        const first2d = phone.replaceAll('+52', '').substring(0, 2);
        const second4d = phone.replaceAll('+52', '').substring(2, 6);
        const last4d = phone.replaceAll('+52', '').substring(6, 10);
        return `(${first2d}) ${second4d}-${last4d}`;
    },
    format_to_claveLada3Digits: function (phone) {
        const first3d = phone.replaceAll('+52', '').substring(0, 3);
        const second3d = phone.replaceAll('+52', '').substring(3, 6);
        const last4d = phone.replaceAll('+52', '').substring(6, 10);
        return `(${first3d}) ${second3d}-${last4d}`;
    },
    format_according_clave_lada: function (phone) {
        if (this.claveLada2digits(phone)) return this.format_to_claveLada2Digits(phone);
        return this.format_to_claveLada3Digits(phone);
    },
    claveLada2digits: function (phone) {
        if (!phone) return false;
        const m2 = phone.substring(0, 2).length == 2 ? phone.substring(0, 2) : null;
        return claves_lada_solo[m2] || null;
    },
    claveLada3digits: function (phone) {
        if (!phone) return false;
        const m3 = phone.substring(0, 3).length == 3 ? phone.substring(0, 3) : null;
        return claves_lada_solo[m3] || null;
    },
    claveLadaIncorrecta: function (phone) {
        if (!phone) return true;
        const clean = this.cleanPhone(phone);
        return !this.claveLada2digits(clean) && !this.claveLada3digits(clean);
    },

    cleanObject: function (obj) {
        Object.keys(obj).forEach((k) => (obj[k] == null || obj[k] == '' || obj[k] == false) && delete obj[k]);
        return obj;
    },
    isObj: function (a) {
        if ((!!a) && (a.constructor === Object)) {
            return true;
        }
        return false;
    },

    _st: function (z, g) {
        return "" + (g != "" ? "[" : "") + z + (g != "" ? "]" : "");
    },
    objectToQuery: function (params, skipobjects, prefix) {
        if (skipobjects === void 0) {
            skipobjects = false;
        }
        if (prefix === void 0) {
            prefix = "";
        }
        var result = "";
        if (typeof (params) != "object") {
            return prefix + "=" + encodeURIComponent(params) + "&";
        }
        for (var param in params) {
            var c = "" + prefix + this._st(param, prefix);
            if (this.isObj(params[param]) && !skipobjects) {
                result += this.objectToQuery(params[param], false, "" + c);
            } else if (Array.isArray(params[param]) && !skipobjects) {
                params[param].forEach(function (item, ind) {
                    result += this.objectToQuery(item, false, c + "[" + ind + "]");
                });
            } else {
                result += c + "=" + encodeURIComponent(params[param]) + "&";
            }
        }
        return result;
    },
    createInitials: function (name) {
        return name
            .split(" ", 2)
            .map((str) => str[0])
            .join("");
    },
    accounts_receivable_business_id: function () {
        const wait_status = store.state.waiting_status.item;
        if (wait_status) {
            const product = wait_status.products.find(product => product.name === Product.AccountsRecivable);
            if (product) return product.track_id;
        }
        return null;
    },
    find_product_by_name: function (name) {
        const wait_status = store.state.waiting_status.item;
        if (wait_status) {
            return wait_status.products.find(product => product.name === name && product.status === ProductStatus.OfferAccepted);
        }
        return null;
    },
    accounts_payable_business_id: function () {
        const wait_status = store.state.waiting_status.item;
        if (wait_status) {
            const product = wait_status.products.find(product => product.name === Product.AccountsPayable);
            if (product) return product.track_id;
        }
        return null;
    },
    show_invoices_from_sat_cxp: function () {
        const wait_status = store.state.waiting_status.item;
        if (wait_status) {
            const product = wait_status.products.find(product => product.name === Product.AccountsPayable);
            if (product) return product.sat;
        }
        return null;
    },
    thousandsSeparationFormat: function (number) {
        const fixed_number = number.toFixed(2);
        const exp = /(\d)(?=(\d{3})+(?!\d))/g;
        const rep = '$1,';
        let arr = fixed_number.toString().split('.');
        arr[0] = arr[0].replace(exp, rep);
        return arr[1] ? arr.join('.') : arr[0];
    },
    translate_info: function (info, section = 'CAUSES') {
        const compound_info = info.trim().split(':');
        if (compound_info.length === 2) {
            const [translate, no_translate] = compound_info;
            if (translate) return `${i18n.t(`EXCEPTIONS.${section}.${translate}`)} ${no_translate}`;
            return no_translate
        } else {
            const [translate] = compound_info;
            return i18n.t(`EXCEPTIONS.${section}.${translate}`);
        }
    },
    processesParams: function (params) {
        if(!params) return '';
        const array_params = params.split(',');
        const formatted = array_params.map((param) => {
            return this.translate_info(param);
        });
        return formatted.length !== 1 ? formatted.join(',') : formatted.join('');
    },
    processesMessages: function (messages) {
        if(!messages) return '';
        const array_messages = messages.split(',');
        const formatted = array_messages.map((message) => {
            return this.translate_info(message, 'MESSAGES');
        });
        return formatted.length !== 1 ? formatted.join(', ').replaceAll(';', ',') : formatted.join('').replaceAll(';', ',');
    },
    get_business_name: function () {
        const wait_status = store.state.waiting_status.item;
        const business_info = wait_status?.business_info ?? "";
        return business_info.business_name || "";
    },

    mayBeAnError(message) {
        if (typeof message?.data === "string") return true;
        const {data: {code, data}} = message;
        return !!(code && data && data?.errors);
    },

    formatFileSize(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
};