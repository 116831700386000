import { getRequestParams } from '@/shared/general-request/config/RequestParams'
import HttpRequest from '@/shared/general-request/HttpRequest'

const { apiV1, apiV2, workflow, bulkService } = getRequestParams()

const httpClientV1: HttpRequest = new HttpRequest(apiV1.url)
const httpClientV2: HttpRequest = new HttpRequest(apiV2.url)
const httpClientWorkflow: HttpRequest = new HttpRequest(workflow.url)

const httpBulkService: HttpRequest = new HttpRequest(bulkService.url)

export { httpClientV1, httpClientV2, httpClientWorkflow, httpBulkService }