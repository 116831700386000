export default {
    iconfont: 'fa4',
    values: {
        address_card: 'fa-address-card',
        arrowright: 'fa-arrow-right',
        bars: 'fa-bars',
        book: 'fa-book',
        building: 'fa-building-o',
        van: 'fa-bus',
        calendar: 'fa-calendar',
        car: 'fa-car',
        check: 'fa-check',
        chevron_down: 'fa-chevron-down',
        chevron_right: 'fa-chevron-right',
        chevron_left: 'fa-chevron-left',
        dropdown:'fa-caret-down',
        circle: 'fa-circle',
        chart_bar: 'fa-bar-chart-o',
        clock: 'fa-clock-o',
        cloud_download: 'fa-cloud-download',
        cogs: 'fa-cogs',
        database: 'fa-database',
        download: 'fa-download',
        edit: 'fa-edit',
        error: 'fa-exclamation-triangle',
        file_excel: 'fa-file-excel-o',
        file_pdf: 'fa-file-pdf-o',
        file_text: 'fa-file-text-o',
        folder_open: 'fa-folder-open',
        home: 'fa-home',
        industry: 'fa-industry',
        id_card: 'fa-id-card-o',
        lock: 'fa-lock',
        mail: 'fa-envelope-o',
        minus_circle: 'fa-minus-circle',
        motorcycle: 'fa-motorcycle',
        object_group: 'fa-object-group',
        paper_plane: 'fa-paper-plane',
        paperclip: 'fa-paperclip',
        pencil: 'fa-pencil',
        pie_chart: 'fa-pie-chart',
        play: 'fa-play',
        plus_cirlce: 'fa-plus',
        puzzle_piece: 'fa-puzzle-piece',
        refresh: 'fa-refresh',
        search: 'fa-search',
        shield: 'fa-shield',
        sign_out: 'fa-sign-out',
        fa_sign_in: 'fa-sign-in',
        sliders: 'fa-sliders',
        tachometer: 'fa-tachometer',
        times: 'fa-times',
        times_circle: 'fa-times-circle',
        truck: 'fa-truck',
        upload: 'fa-upload',
        user: 'fa-user',
        users: 'fa-users',
        excel_file: 'fa-file-excel-o',
        cloud_upload: 'fa-cloud-upload',
        eye: 'fa-eye',
        eye_off: 'fa-eye-slash',
        file: 'fa-file',
        plus: 'fa-plus',
        whatsapp: 'fa-whatsapp',
        password_key: 'fa-key',
        file_invoice: 'fa-file-invoice',
        save: 'fa-save',
        hamburger: 'fa-bars',
        trash: 'fa-trash-o',
        bell: 'fa-bell',
    }
}