import {defaultState, getters, mutations} from "@/plugins/store/defaultServiceState";
import store from "@/plugins/store";
import { apiBaseURL } from '@/config/app.config';
import Vue from "vue";

export default {
    namespaced: true,
    state: {
        ...defaultState,
    },
    getters: {
        ...getters
    },
    mutations: {
        ...mutations
    },
    actions: {

        fetchCreditLineSummary({commit}) {
            commit('isLoading', true);
            commit('error', null);
            const base_url = apiBaseURL;
            const url = `${base_url}/line-of-credit/summary`;
            const token = store.state.security.jwt;
            Vue.prototype.$axios(
                {
                    method: "GET",
                    url,
                    headers: {
                        "Authorization":
                            `Bearer ${token}`,
                    }
                })
                .then(async function (response) {
                    commit('isLoading', false);
                    commit('item', response.data);
                })
                .catch(() => {
                    commit('isLoading', false);
                    const e = {
                        type: 'error',
                        data: `Por el momento no se puede obtener la información de tu línea de crédito.`
                    };
                    commit('app/reportMessage', e, {root: true});
                });
        },
    }
}
