import Vue from "vue"
import Router from "vue-router"
import store from "@/plugins/store/index"
import layoutRoutes from '@/modules/layout/routes'
import loginRoutes from '@/modules/login/routes'
import MENU_ITEMS_BY_ROLES from '@/shared/const/MenuItemsByRoles'

Vue.use(Router)

const router = new Router({
    mode: "history",
    routes: [
        ...loginRoutes,
        {
            path: "/register",
            name: "Register",
            component: () => import("@/components/Pages/OnBoarding"),
            meta: { guest: true },
        },
        {
            path: "/signup-notification",
            component: () =>
                import("@/components/Pages/SignupNotification"),
            meta: { guest: true },
            name: "signup-notification",
        },
        {
            path: "/forgot-password",
            component: () =>
                import("@/components/Pages/ForgotPassword"),
            meta: { guest: true },
            name: "forgot-password",
        },
        {
            path: "/change_forgot_password/:key",
            name: "change_forgot_password",
            meta: { guest: true },
            component: () => import("@/components/Pages/ChangeForgotPassword"),
        },
        {
            path: "/validate/:key",
            name: "verify-signup",
            component: () => import("@/components/app/VerifyUser/VerifyUser.vue"),
            meta: { guest: true },
        },
        {
            path: "/onboarding2",
            name: "onboarding2",
            component: () => import("@/components/Pages/Onboarding2"),
            meta: { guest: true },
        },
        {
            path: "/maintenance",
            name: "maintenance",
            component: () => import("@/components/Pages/Maintenance"),
            meta: { guest: true },
        },
        {
            path: "/cellphone-validate",
            name: "VALIDATE_PHONE",
            component: () => import("@/components/Pages/CellphoneValidate"),
        },
        {
            path: "/testing-page",
            name: "testing-page",
            component: () => import("@/components/Pages/Onboarding2/forms/ReferencesForm"),
            meta: { guest: true },
        },
        {
            path: "/pending-kyc-documents",
            name: "UPDATE_EXTRA_DOCUMENTS",
            component: () => import("@/components/Pages/KYCDocuments"),
        },
        {
            path: "/loading",
            name: "loading",
            component: () => import("@/components/Pages/Loader"),
        },
        {
            path: "/home",
            name: "CHOOSE_PRODUCT",
            component: () => import("@/modules/layout/home/Home.vue"),
            meta: { guest: true },
        },
        ...layoutRoutes,
        {
            path: '*',
            component: () => import("@/components/app/NotFound.vue"),
        },
    ],
});

router.beforeEach(async (to, from, next) => {
    const publicUrls = [
        'login',
        'register',
        'forgot-password',
        'change_forgot_password',
        'login-carriers',
        'login-suppliers',
        'signup-notification',
        'validate',
        'maintenance',
        'cellphone-validate',
        'loading',
        'home',
        'testing-page',
        'onboarding2',
        'pending-kyc-documents',
        'loading',
        'verify-signup',
        'change_forgot_password'
    ]

    const isAuth = store.state.security.jwt != null
    const userRoles = store.state.security.roles
    const mainMenuOption = isAuth ? MENU_ITEMS_BY_ROLES[userRoles[0]][0] : undefined

    if (to.name === 'onboarding2' && isAuth) {
        return next()
    }

    if (to.meta.guest) {
        if (isAuth && publicUrls.includes(to.name)) {
            return next({ name: mainMenuOption })
        }
        return next()
    }

    if (to.meta.requiresAuth && !isAuth) {
        return next({ name: 'login' })
    }

    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!isAuth) {
            return next({ name: 'login' })
        }
        try {
            await store.dispatch('security/verifyJwt')
        } catch (error) {
            return next({ name: 'login' })
        }
    }

    if (to.meta.requireRoles) {
        const hasAccess = to.meta.requireRoles.some(role => userRoles.includes(role))
        if (!hasAccess) {
            return next({ name: mainMenuOption })
        }
    }
    next()
})

export default router;

