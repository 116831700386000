import {defaultState, getters, mutations} from '@/plugins/store/defaultServiceState'
import store from "@/plugins/store";
import Vue from "vue";

export default {
    namespaced: true,
    state: {
        ...defaultState,
        isSending: false,
        sent: false,
        isValidating: false,
        verified: false
    },
    getters: {
        ...getters,
        isSending(state) {
            return state.isSending;
        },
        sent(state) {
            return state.sent;
        },
        isValidating(state) {
            return state.isValidating;
        },
        isVerified(state) {
            return state.verified;
        },
    },
    mutations: {
        ...mutations,
        isSending(state, val) {
            state.isSending = val;
        },
        setSent(state, val) {
            state.sent = val;
        },
        isValidating(state, val) {
            state.isValidating = val;
        },
        isVerified(state, val) {
            state.verified = val;
        },
    },
    actions: {
        // old way to do this...
        validate({commit}, nip) {
            const token = store.state.security.jwt;
            commit('isSaving', true);
            commit('item', null);
            Vue.prototype.$axios(
                {
                    method: "POST",
                    url: "/api/v1/carriers/contact_info/validate",
                    data: {nip},
                    headers: {
                        "Authorization": `${token}`,
                    }
                }).then(function (response) {

                const w = {...store.state.waiting_status.item};
                w.need_wait = true;
                commit('waiting_status/item', w, {root: true});

                commit('item', response.data.data);
                commit('error', null);
                commit('isSaving', false);

            })
                .catch(error => {
                    commit('isSaving', false);
                    commit('error', error.response.data || error.message)
                });

        },
        validate_on_register({commit}, phone) {
            commit('error', null);
            commit('isSending', true);
            Vue.prototype.$axios(
                {
                    method: "POST",
                    url: "/api/v1/users/phone_validation",
                    data: {phone},
                }).then(function (response) {
                commit('item', response.data.data);
                commit('isSending', false);
                commit('setSent', true);

            }).catch(error => {
                commit('isSending', false);
                commit('setSent', false);

                const e = {type: 'error', data: error.response.data};
                commit('app/reportMessage', e, {root: true});

            });
        },
        send_validation({commit}, payload) {
            const {phone, code} = payload;
            commit('error', null);
            commit('isValidating', true);
            Vue.prototype.$axios(
                {
                    method: "POST",
                    url: "/api/v1/validations/phone",
                    data: {phone, code},
                }).then(function (response) {

                commit('item', response.data.data);

                commit('isValidating', false);
                commit('isVerified', true);
            })
                .catch(error => {
                    commit('isValidating', false);
                    commit('isVerified', false);

                    const e = {type: 'error', data: error.response.data};
                    commit('app/reportMessage', e, {root: true});

                });
        }
    }
}