import Pusher from 'pusher-js'

const API_KEY = process.env.VUE_APP_PUSHER_API_KEY
const CLUSTER = 'us2'
const STATUS_EVENT = 'status-event'
const SAT_CREDENTIAL_EVENT = 'sat-credential-event'
const EXTRACTION_EVENT = 'extraction-event'
const ENTITY_MODIFIED_EVENT = 'entity-modified'
const EXPORTED_EVENT = 'data-exported';
const INCODE_ONBOARDING_FINISHED_EVENT = 'incode-onboarding-finished'

const pusherInstance = new Pusher(API_KEY, {
    cluster: CLUSTER,
})

const subscribeToChannel = (channelName) => {
    let channel = obtainChannel(channelName)

    if (!channel) {
        channel = pusherInstance.subscribe(channelName)
    }

    return channel
}

const unsubscribeFromChannel = (channelName) => {
    const channel = obtainChannel(channelName)
    if (channel) {
        channel.unbind_all()
        pusherInstance.unsubscribe(channelName)
    }
}

const unsubscribeAllChannels = () => {
    const channels = pusherInstance.allChannels()
    channels.forEach(channel => pusherInstance.unsubscribe(channel.name))
}

const obtainChannel = (channelName) => {
    return pusherInstance.channel(channelName)
}

export {
    pusherInstance,
    subscribeToChannel,
    unsubscribeFromChannel,
    obtainChannel,
    unsubscribeAllChannels,
    STATUS_EVENT,
    SAT_CREDENTIAL_EVENT,
    EXTRACTION_EVENT,
    ENTITY_MODIFIED_EVENT,
    EXPORTED_EVENT,
    INCODE_ONBOARDING_FINISHED_EVENT
}
