export default class Message {
   
    constructor(icon,header,message,cancelar,aceptar,funcionCancelar,funcionAceptar){
      this.icon = icon;
      this.header = header;
      this.message = message;
      this.cancelar = cancelar;
      this.aceptar = aceptar;
      this.funcionCancelar = funcionCancelar;
      this.funcionAceptar =funcionAceptar;
    }
  
}