import Cookies from 'js-cookie';
import { cookieDomain } from '@/config/app.config'


export default function createStatePersistedPlugin(options) {
    return store => {
        store.subscribe((mutation, state) => {
            if (mutation.type.startsWith(options.moduleName + '/')) {
                const stateToPersist = JSON.stringify(state[options.moduleName]);

                Cookies.set(options.cookieName, stateToPersist, {
                    expires: 1, // in days
                    secure: true,
                    domain: cookieDomain
                });
            }
        });
    };
}
