import Vue from 'vue'
import Vuex from 'vuex'
import createStatePersistedPlugin from './plugins/persistStatePlugin';
import app from './modules/app';
import security from './modules/security/security';
import business_info from './modules/onboarding/business_info';
import ciec from './modules/onboarding/ciec';
import extra_documents from './modules/onboarding/extra_documents';
import files from './modules/utils/files';

import user from './modules/security/user';
import session from '../../shared/store/session';
import verify_email from './modules/security/verify_email';

import waiting_status from './modules/carrier/waiting_status';

import bill from './modules/accounts_receivable/bill';
import phone_validation from './modules/security/phone_validation';

import address_from_zipcode from './modules/onboarding/address_from_zip_code';
import shareholders from './modules/onboarding/shareholders';
import feature_flag from "@/plugins/store/modules/utils/feature_flag";
import products from "@/plugins/store/modules/carrier/products";
import sat from "@/plugins/store/modules/credit_line/sat";
import extraction_info from "@/plugins/store/modules/credit_line/extraction_info";
import invoices_loads from "@/plugins/store/modules/accounts_payable/invoices_loads";
import invoices_loadsBundled from "@/plugins/store/modules/accounts_payable/invoices_loadsBundled";
import providers from "@/modules/suppliers/store/providers";
import invoices_sat from "@/plugins/store/modules/accounts_payable/invoices_sat";
import invoices_loads_details from "@/plugins/store/modules/accounts_payable/invoices_loads_details";
import kyc from "@/plugins/store/modules/carrier/kyc";
import references from "@/plugins/store/modules/onboarding/references";
import pusher from "@/plugins/store/modules/utils/pusher";
import credit_line_summary from "@/plugins/store/modules/credit_line/credit_line_summary";
import lineOfCreditInvoices from "@/plugins/store/modules/WelcomePage/LineOfCreditInvoices";
import creditsInfo from "@/plugins/store/modules/WelcomePage/creditsInfo";
import { cookieName } from '@/config/app.config'
import pagination from '@/ui-lib/atoms/custom-pagination/store/StorePagination'
import { loadsFilter } from '@/modules/loads-deliveries/store/StoreLoadsFilter'

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        app,
        // carrier,
        security,
        // contact_info,
        business_info,
        // main_stakeholder,
        ciec,
        // credit_circle,
        user,
        session,
        verify_email,
        waiting_status,

        bill,

        extra_documents,

        files,
        phone_validation,
        address_from_zipcode,
        shareholders,

        feature_flag,
        products,
        sat,
        extraction_info,

        invoices_loads,
        invoices_loadsBundled,
        providers,
        invoices_sat,
        invoices_loads_details,
        kyc,
        references,
        pusher,
        credit_line_summary,

        creditsInfo,
        lineOfCreditInvoices,


        pagination,
        loadsFilter,
    },
    state: {
        //YOU CAN DEFINE ALL STATE VARIABLES HERE
    },
    getters: {
        //TO GET STATE
    },
    mutations: {
        //HERE IS WHERE THE STATE CHANGE
    },
    actions: {
        //HERE YOU CAN CALL ASYNC METHODS TO MUTATE THE STATE
    },
    plugins: [
        createStatePersistedPlugin({
            moduleName: 'security',
            cookieName: cookieName
        }),
    ],
});
