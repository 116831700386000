import {defaultState, getters, mutations} from '@/plugins/store/defaultServiceState';
import store from "@/plugins/store";
import {MyFunctions} from "@/components/app/Utils/functions";
import Vue from "vue";

export default {
    namespaced: true,
    state: {
        ...defaultState
    },
    getters: {
        ...getters
    },
    mutations: {
        ...mutations
    },
    actions: {
        fetchInvoicesSat({commit}, params) {
            const token = store.state.security.jwt;
            commit('isLoading', true);
            commit('error', null);
            Vue.prototype.$axios(
                {
                    method: "GET",
                    url: `/api/v1/invoices/sat/unprocessed?${params || ''}`,
                    // params,
                    headers: {
                        "Authorization":
                            `${token}`,
                    }
                })
                .then(function (response) {
                    commit('items', response.data.data);
                    commit('isLoading', false);

                })
                .catch((error) => {
                    commit('isLoading', false);
                    const e = {type: 'error', data: error?.response?.data};
                    commit('app/reportMessage', e, {root: true});
                });
        },
        async authorizeInvoices({commit}, payload) {
            const token = store.state.security.jwt;
            commit('isSaving', true);
            commit('error', null);
            return Vue.prototype.$axios(
                {
                    method: "POST",
                    url: "/api/v1/accounts_payable/invoices/sat",
                    data: payload,
                    headers: {
                        "Authorization": `${token}`,
                    }
                })
                .then(function (response) {

                    commit('isSaving', false);
                    MyFunctions.dispatchTrackEvent("authorizeInvoiceSatSuccess")
                    return response;
                })
                .catch(error => {
                    commit('isSaving', false);
                    const e = {type: 'error', data: error.response.data};
                    commit('app/reportMessage', e, {root: true});
                    MyFunctions.dispatchTrackEvent("authorizeInvoiceSatError");
                    return error;
                });
        },
    }
}