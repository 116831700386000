import loadsAndDeliveriesRoutes from '@/modules/loads-deliveries/routes'
import customersRoutes from '@/modules/customers/routes'
import suppliersRoutes from '@/modules/suppliers/routes'
import invoicesRoutes from '@/modules/invoices/routes'
import welcomeRoutes from '@/modules/welcome/routes'
import accountsPayableRoutes from '@/modules/accounts-payable/routes'
import { Role } from '@/shared/enums/Role'
import { MenuItem } from '@/shared/enums/MenuItem'

export default [
    {
        path: '/',
        component: () => import('./home/Home.vue'),
        meta: { requiresAuth: true },
        children: [
            {
                path: '/rp',
                name: 'rp',
                component: () => import('@/components/Pages/PowerBi'),
                meta: { requireRoles: [Role.Admin, Role.FinancialManagement, Role.PlanningAuditor, Role.LegacyAccess] },
            },
            {
                path: '/tariff',
                name: MenuItem.Tariff,
                component: () => import('@/components/Pages/Tariff'),
                meta: { requireRoles: [Role.Admin, Role.FinancialManagement, Role.PlanningAuditor, Role.LegacyAccess] },
            },
            {
                path: '/metrics',
                name: MenuItem.Metrics,
                component: () => import('@/components/Pages/Metrics'),
                meta: { requireRoles: [Role.Admin, Role.FinancialManagement, Role.PlanningAuditor, Role.LegacyAccess] },
            },
            ...accountsPayableRoutes,
            ...loadsAndDeliveriesRoutes,
            ...customersRoutes,
            ...suppliersRoutes,
            ...invoicesRoutes,
            ...welcomeRoutes,
        ]
    }
]

