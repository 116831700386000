import {defaultState, getters, mutations} from '@/plugins/store/defaultServiceState'
import store from "@/plugins/store";
import {MyFunctions} from "@/components/app/Utils/functions";
import Vue from "vue";

export default {
    namespaced: true,
    state: {
        ...defaultState
    },
    getters: {
        ...getters
    },
    mutations: {
        ...mutations
    },
    actions: {
        async saveBill({commit}, bill) {
            let response_success = false;
            const token = store.state.security.jwt;

            let formData = new FormData();

            Object.keys(bill).map(key => {
                formData.append(`${key}`, bill[key]);
            });
            commit('isSaving', true);
            commit('error', null);
            await Vue.prototype.$axios(
                {
                    method: "POST",
                    url: "/api/v1/invoices",
                    data: formData,
                    headers: {
                        "Authorization": `${token}`,
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(function (response) {
                    commit('item', response.data.data);
                    commit('error', null);
                    commit('isSaving', false);
                    // router.push({name: 'carriers'});
                    response_success = true;
                    const e =
                        {type: 'success', data: '¡Todo listo!, tu factura está cargada correctamente.'};
                    commit('app/reportMessage', e, {root: true});
                    MyFunctions.dispatchTrackEvent("uploadIndividualInvoiceSuccess");
                    MyFunctions.dispatchTrackEvent("SolventoCobra.Carga.CargaIndividual.Exito");
                })
                .catch(error => {
                    commit('isSaving', false);
                    const e = {type: 'error', data: error.response.data};
                    commit('app/reportMessage', e, {root: true});
                    response_success = false;
                    MyFunctions.dispatchTrackEvent("uploadIndividualInvoiceError");
                    MyFunctions.dispatchTrackEvent("SolventoCobra.Carga.CargaIndividual.Error", error.response.data);
                });
            return response_success;

        },
        async saveMassiveBills({commit}, payload) {
            let response_success = false;
            const token = store.state.security.jwt;
            let formData = new FormData();

            Object.keys(payload).map(key => {
                formData.append(`${key}`, payload[key]);
            });
            commit('isSaving', true);
            await Vue.prototype.$axios(
                {
                    method: "POST",
                    url: "/api/v1/carriers/invoices/bulk",
                    data: formData,
                    headers: {
                        "Authorization": `${token}`,
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(function (response) {
                    commit('items', response.data.data.invoices);
                    commit('error', null);
                    commit('isSaving', false);
                    response_success = true;

                    const e = {type: 'success', data: '¡Todo listo!, tus facturas están cargadas correctamente.'};
                    commit('app/reportMessage', e, {root: true});

                    MyFunctions.dispatchTrackEvent("uploadMassiveInvoiceSuccess");
                    MyFunctions.dispatchTrackEvent("SolventoCobra.Carga.CargaMasiva.Exito");
                })
                .catch(error => {
                    commit('isSaving', false);
                    response_success = false;

                    const e = {type: 'error', data: error.response.data};
                    commit('app/reportMessage', e, {root: true});

                    MyFunctions.dispatchTrackEvent("uploadMassiveInvoiceError");
                    MyFunctions.dispatchTrackEvent("SolventoCobra.Carga.CargaMasiva.Error", error.response.data);
                });

            return response_success;
        },
        fetchBills({commit}, query) {
            const token = store.state.security.jwt;
            commit('isLoading', true);
            Vue.prototype.$axios(
                {
                    method: "GET",
                    url: `/api/v1/invoices?${query}`,
                    headers: {
                        "Authorization": `${token}`,
                    }
                })
                .then(function (response) {
                    commit('items', response.data.data);
                    commit('error', null);
                    commit('isLoading', false);
                })
                .catch(error => {
                    commit('isLoading', false);
                    const e = {type: 'error', data: error.response.data};
                    commit('app/reportMessage', e, {root: true});
                });
        },

    }
}