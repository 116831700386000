import Vue from 'vue';
import router from '@/plugins/router';
import {defaultState, mutations, getters} from '@/plugins/store/defaultSessionState'
import VueJwtDecode from 'vue-jwt-decode';
import {MyFunctions} from '@/components/app/Utils/functions';
import {mixpanelSiteId} from '@/config/app.config';
import i18n from '@/plugins/i18n'
import validateHasAuditProduct from '@/shared/utils/ValidateHasAuditProduct'
import authRequestClient from '@/modules/login/api-client/AuthRequestClient'
import globalMethodsMixin from '@/shared/mixins/globalMethodsMixin';

export default {
  namespaced: true,
  state: {
    ...defaultState
  },
  mutations: {
    ...mutations
  },
  actions: {
    login: async ({commit, dispatch}, credentials) => {

      try {
        commit('isAuthenticating', true);
        dispatch('app/clearToastMessages', null, {root: true});

        const response = credentials.isSupplier
          ? await authRequestClient.authSupplier(credentials)
          : await authRequestClient.authCarrier(credentials)

        const {token, refreshToken, email} = response.data;
        const decodedJwt = VueJwtDecode.decode(token);

        if (credentials.isSupplier && !decodedJwt.taxId) {
          throw new Error('Invalid supplier taxId')
        }

        commit('security/setJwt', token, {root: true});
        commit('security/setRefreshToken', refreshToken, {root: true});
        commit('user', {email});
        commit('sessionStarted', true);
        commit('error', null);
        Vue.prototype.$session.set('user', {email});

        commit('loginRetries', 0);

        const id = credentials.isSupplier ? decodedJwt.sid : decodedJwt.id
        MyFunctions.identifyDnDataAnalysisSites(id, {email});
        MyFunctions.setPersonalInfoDnDataAnalysisSites({
          '$email': email,
        });

        await dispatch('waiting_status/carrierStatus', token, {root: true});

        commit('isAuthenticating', false);
        const hasAuditProduct = credentials.isSupplier ? true : await validateHasAuditProduct()
        commit('security/setHasAuditProduct', hasAuditProduct, {root: true})

        MyFunctions.dispatchTrackEvent('IniciaSesion.Exito');
        if (localStorage.getItem('vuex') !== null) {
          localStorage.removeItem('vuex');
        }
      } catch (error) {
        commit('loginRetries', credentials.loginAttempts + 1);
        commit('isAuthenticating', false);
        commit('sessionStarted', false);

        const errorMessage =
          error.response?.data.code === 'INVALID_CREDENTIALS'
            ? i18n.t('The login data provided is invalid')
            : i18n.t('We have not been able to log you in. Please try again.')

        MyFunctions.dispatchTrackEvent('IniciaSesion.Error', error?.response?.data || 'Login fail');
        return errorMessage;
      }
    },

    async apiLogout({rootState}, isSupplier) {
      const {refreshToken} = rootState.security;

      if (refreshToken) {
        try {
          isSupplier
            ? await authRequestClient.logoutSupplier(refreshToken)
            : await authRequestClient.logoutCarrier(refreshToken)
        } catch (e) {
          console.log(e);
        }
      }
    },
    cleanSessionStorage: function () {
      Vue.prototype.$session.remove('user');
      Vue.prototype.$session.remove('product_name');
    },
    closePusherConnection: function ({dispatch}) {
      dispatch('pusher/closeConnection', null, {root: true});
    },
    logout: ({commit, dispatch, rootGetters}) => {

      if (!rootGetters['security/getJwt']) {
        return
      }

      const isSupplier = rootGetters['security/isSupplier']

      dispatch('apiLogout', isSupplier);

      commit('security/setJwt', null, {root: true});
      commit('security/setRefreshToken', null, {root: true});
      commit('clearState');
      commit('waiting_status/item', null, {root: true});
      dispatch('cleanSessionStorage');
      MyFunctions.dispatchTrackEvent('CerrarSesion.Click');
      if (mixpanelSiteId)
        Vue.prototype.mixpanel.reset();
      dispatch('closePusherConnection');
      localStorage.removeItem('interviewId')


      const loginName = isSupplier ? 'login-suppliers' : 'login-carriers'
      router.push({name: loginName})
      globalMethodsMixin.methods.clearFilesAndClose()
    },
  },
  getters: {
    ...getters
  }
}
