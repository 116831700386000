import {defaultState, getters, mutations} from '@/plugins/store/defaultServiceState'
import store from "@/plugins/store";
import Vue from "vue";

export default {
    namespaced: true,
    state: {
        ...defaultState
    },
    getters: {
        ...getters
    },
    mutations: {
        ...mutations
    },
    actions: {
        fetchCiecInfo({commit}) {
            const token = store.state.security.jwt;
            const url = '/api/v1/carriers/extraction_info';
            Vue.prototype.$axios(
                {
                    method: "GET",
                    url,
                    headers: {
                        "Authorization": `${token}`,
                    }
                })
                .then(function (response) {
                    commit('item', response.data.data);
                    commit('error', null);
                    commit('isSaving', false);
                })
                .catch(error => {
                    commit('isSaving', false);
                    const e = {type: 'error', data: error.response.data};
                    commit('app/reportMessage', e, {root: true});

                });
        },
    },

}