const defaultState = {
    item: null,
    items: [],
    itemsStatuses: {},
    total: 0,
    page: 1,
    itemsPerPage: 10,
    isLoading: false,
    isLoadingSingle: false,
    isSaving: false,
    isRemoving: false,
    error: null,
    generalConfigs:{},

}

const getters = {
    item(state) {
        return state.item;
    },
    items(state) {
        return state.items;
    },
    itemsStatuses(state) {
        return state.itemsStatuses;
    },
    total(state) {
        return state.total;
    },
    page(state) {
        return state.page;
    },
    itemsPerPage(state) {
        return state.itemsPerPage;
    },
    isLoading(state) {
        return state.isLoading;
    },
    isLoadingSingle(state) {
        return state.isLoadingSingle;
    },
    isSaving(state) {
        return state.isSaving;
    },
    isRemoving(state) {
        return state.isRemoving;
    },
    error(state) {
        return state.error;
    },
    generalConfigs(state) {
        return state.generalConfigs;
    },

}
const mutations = {
    item(state, item) {
        state.item = item;
    },
    items(state, items) {
        state.items = items;
        state.itemsStatuses = {};
        if(Array.isArray(items))
        items.map((item) => {
            const {id = null} = item;
            if (id) state.itemsStatuses[id] = {saving: false, loading: false, error: null}
        });
    },
    itemsZipCode(state, items) {
        state.items = items;
    },
    itemsStatuses(state, statuses) {
        state.itemsStatuses = statuses;
    },
    total(state, total) {
        state.total = total;
    },
    page(state, page) {
        state.page = page;
    },
    itemsPerPage(state, itemsPerPage) {
        state.itemsPerPage = itemsPerPage;
    },
    isLoading(state, isLoading) {
        state.isLoading = isLoading;
    },
    isLoadingSingle(state, isLoadingSingle) {
        state.isLoadingSingle = isLoadingSingle;
    },
    isSaving(state, isSaving) {
        state.isSaving = isSaving;
    },
    isRemoving(state, isRemoving) {
        state.isRemoving = isRemoving;
    },
    error(state, error) {
        state.error = error;
    },
    clearState(state) {
        state.item = null;
        state.items = [];
        state.itemsStatuses = {};
        state.total = 0;
        state.page = 1;
        state.isLoading = false;
        state.isLoadingSingle = false;
        state.isSaving = false;
        state.isRemoving = false;
        state.error = null;
        state.generalConfigs = {};
    },
    generalConfigs(state, config) {
        return state.generalConfigs = {...config};
    },
}

module.exports = {defaultState, getters, mutations};