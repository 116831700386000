import {defaultState, getters, mutations} from '@/plugins/store/defaultServiceState'
import store from "@/plugins/store";
import Vue from "vue";

export default {
    namespaced: true,
    state: {
        ...defaultState
    },
    getters: {
        ...getters
    },
    mutations: {
        ...mutations
    },
    actions: {
        doExtraction({commit}, payload) {
            const token = store.state.security.jwt;
            commit('isLoading', true);
            Vue.prototype.$axios(
                {
                    method: "POST",
                    url: "/api/v1/carriers/business_info_sat",
                    data: {...payload},
                    headers: {
                        "Authorization": `${token}`,
                    }
                })
                .then(function (response) {
                    commit('item', response.data.data);
                    commit('error', null);
                    commit('isLoading', false);
                })
                .catch(error => {
                    commit('isLoading', false);
                    commit('error', error.response.data || error.message)
                });
        },
    },

}