import {defaultState, getters, mutations} from '@/plugins/store/defaultServiceState'
import store from "@/plugins/store";
import {MyFunctions} from "@/components/app/Utils/functions";
import Vue from "vue";

export default {
    namespaced: true,
    state: {
        ...defaultState
    },
    getters: {
        ...getters
    },
    mutations: {
        ...mutations
    },
    actions: {
        saveBusinessInfo({commit, dispatch}, payload) {
            const token = store.state.security.jwt;
            // clean object functions is usefully for remove properties that contain null, false or ''(empty)
            payload = MyFunctions.cleanObject(payload);
            commit('isSaving', true);
            commit('error', null);
            Vue.prototype.$axios(
                {
                    method: "POST",
                    url: "/api/v1/carriers/business_info",
                    data: {...payload},
                    headers: {
                        "Authorization": `${token}`,
                    }
                })
                .then(function (response) {

                    const w = {...store.state.waiting_status.item};
                    w.need_wait = true;

                    commit('waiting_status/item', w, {root: true});

                    commit('item', response.data.data);
                    commit('error', null);
                    commit('isSaving', false);
                    dispatch('waiting_status/carrierStatus', token, {root: true});
                    commit('isSaving', false);

                })
                .catch(error => {
                    commit('isSaving', false);
                    const e = {type: 'error', data: error.response.data};
                    commit('app/reportMessage', e, {root: true});
                });

        },
        complementaryBusinessInfo({commit}, payload) {
            const token = store.state.security.jwt;
            commit('isSaving', true);
            commit('error', null);
            Vue.prototype.$axios(
                {
                    method: "POST",
                    url: "/api/v1/carriers/business_info/complementary",
                    data: {...payload},
                    headers: {
                        "Authorization": `${token}`,
                    }
                })
                .then(function (response) {
                    commit('item', response.data.data);
                    commit('error', null);
                    commit('isSaving', false);

                    const w = {...store.state.waiting_status.item};
                    w.need_wait = true;

                    commit('waiting_status/item', w, {root: true});

                })
                .catch(error => {
                    commit('isSaving', false);
                    commit('error', error.response.data);
                });

        },
        getBusinessInfo({commit}, token) {
            commit('isLoadingSingle', true);
            commit('error', null);
            Vue.prototype.$axios(
                {
                    method: "GET",
                    url: "/api/v1/carriers/business_info",
                    headers: {
                        "Authorization": `${token}`,
                    }
                })
                .then(function (response) {
                    commit('item', response.data.data);
                    commit('error', null);
                    commit('isLoadingSingle', false);
                })
                .catch(error => {
                    commit('isLoadingSingle', false);
                    const e = {type: 'error', data: error.response.data};
                    commit('app/reportMessage', e, {root: true});
                });
        },
        setBusinessInfo({commit, dispatch}, payload) {
            const token = store.state.security.jwt;
            commit('isSaving', true);
            commit('error', null);
            Vue.prototype.$axios(
                {
                    method: "PATCH",
                    url: "/api/v1/carriers/business_info",
                    data: {...payload},
                    headers: {
                        "Authorization": `${token}`,
                    }
                })
                .then(function (response) {
                    commit('item', response.data.data);
                    commit('error', null);
                    commit('isSaving', false);
                    const w = {...store.state.waiting_status.item};
                    w.need_wait = true;
                    dispatch('carrier/completeCarrierStep', true, {root: true});
                    commit('waiting_status/item', w, {root: true});
                })
                .catch(error => {
                    commit('isSaving', false);
                    const e = {type: 'error', data: error.response.data};
                    commit('app/reportMessage', e, {root: true});
                });

        },
    }
}