import Vue from 'vue'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import 'dayjs/locale/es'
import advancedFormat from "dayjs/plugin/advancedFormat"
import isToday from "dayjs/plugin/isToday"
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("America/Mexico_City")
dayjs.locale('es')
dayjs.extend(advancedFormat);
dayjs.extend(isToday);
dayjs.extend(relativeTime);
Vue.prototype.$dayjs = dayjs
