import Vue from 'vue';
import router from '@/plugins/router';
import {defaultState, mutations, getters} from '@/plugins/store/defaultServiceState'
import {MyFunctions} from "@/components/app/Utils/functions";

export default {
    namespaced: true,
    state: {
        ...defaultState
    },
    mutations: {
        ...mutations
    },
    getters: {
        ...getters
    },
    actions: {
        register: ({commit, dispatch}, credentials) => {
            commit('isSaving', true);
            const payload = {...credentials, phone: `+52${MyFunctions.cleanPhone(credentials.phone)}`}
            Vue.prototype.$axios.post('/api/v1/users/register', {...payload}).then(function (response) {
                commit('error', null);
                commit('verify_email/error', null, {root: true});
                commit('phone_validation/error', null, {root: true});

                Vue.prototype.$session.set("register", {
                    email: credentials.email,
                    phone: payload.phone,
                    name: payload.first_name
                });
                commit('security/setJwt', response.data.data.token, {root: true});
                const email = response.data.data.email;
                commit('session/user', {email}, {root: true});
                commit('session/isAuthenticating', false, {root: true});
                commit('session/sessionStarted', true, {root: true});
                commit('error', null);
                Vue.prototype.$session.set("user", {email});
                Vue.prototype.$FS.identify(email, {
                    displayName: email,
                    email: email,
                    reviewsWritten_int: 14
                });
                const {token} = response.data.data;
                dispatch('waiting_status/carrierStatus', token, {root: true});
                commit('isSaving', false);
                const {first_name} = credentials;
                const e = {
                    type: 'success',
                    data: `Hola ${first_name}, continúa el proceso de registro en tu Cuenta Solvento.`
                };
                commit('app/reportMessage', e, {root: true});
                MyFunctions.dispatchTrackEvent("Registro.Exito");
            }).catch(error => {
                commit('isSaving', false);
                const e = {type: 'error', data: error.response.data};
                commit('app/reportMessage', e, {root: true});
                MyFunctions.dispatchTrackEvent("Registro.Error", error.response.data);
            });

        },
        changePassword: ({commit}, payload) => {
            const {password, token} = payload;
            commit('isSaving', true);
            Vue.prototype.$axios.patch('/api/v1/users/change_password', {
                token, password
            }, {
                headers: {
                    "Authorization": `${token}`,
                }
            }).then(function () {
                commit('error', null);
                commit('isSaving', false);
                router.push({name: 'login'});
            }).catch(error => {
                commit('isSaving', false);
                const e = {type: 'error', data: error.response.data};
                commit('app/reportMessage', e, {root: true});
            });

        },
        sendEmail: ({commit}, email) => {
            commit('isSaving', true);
            Vue.prototype.$session.set("register", {
                email,
            });
            Vue.prototype.$axios.post('/api/v1/users/forgot_password_email', {
                email
            }).then(function () {
                commit('error', null);
                commit('isSaving', false);

                router.push({name: 'signup-notification'});
            }).catch(error => {
                commit('isSaving', false);
                const {code = null} = error?.response?.data;
                if(code==="INVALID_CREDENTIALS"){
                    router.push({name: 'signup-notification'});
                }else{
                    const e = {type: 'error', data: error.response.data};
                    commit('app/reportMessage', e, {root: true});
                }
            });
        }
    },

}