import { Role } from '@/shared/enums/Role'
import { MenuItem } from '@/shared/enums/MenuItem'

export default [
    {
        path: 'suppliers',
        name: MenuItem.Suppliers,
        component: () => import('@/modules/suppliers/views/Suppliers.vue'),
        meta: { requireRoles: [Role.Admin, Role.FinancialManagement, Role.PlanningAuditor, Role.LegacyAccess] },
    }
]
