import { Role } from '@/shared/enums/Role'
import { MenuItem } from '@/shared/enums/MenuItem'

export default [
    {
        path: 'welcome',
        name: MenuItem.Welcome,
        component: () => import('@/modules/welcome/views/Welcome.vue'),
        meta: { requireRoles: [Role.Admin, Role.FinancialManagement, Role.PlanningAuditor, Role.LegacyAccess, Role.Supplier] },
    }
]
