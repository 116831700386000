import Vue from 'vue'
import currencyFormat from './currencyFormat'
import dateFormat from './dateFormat'
import dateTimeFormat from '@/shared/filters/dateTimeFormat'
import { truncateText } from '@/shared/filters/TruncateText.ts'

Vue.filter('currencyFormat', currencyFormat)
Vue.filter('dateFormat', dateFormat)
Vue.filter('dateTimeFormat', dateTimeFormat)
Vue.filter('truncateText', truncateText)
