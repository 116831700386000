import store from '@/shared/components/loading-loads-files-analyzer/store/store';
import {File} from '@/shared/components/loading-loads-files-analyzer/interfaces/File';

export default {
    showLoadsFilesAnalyzer(): boolean {
        return store.state.show
    },
    toggleShowLoadsFilesAnalyzer(show: boolean): void {
        store.dispatch('toggleShow', show).then()
    },
    getFiles(): File[] {
        return store.state.files
    },
    clearFiles(): void {
        store.commit('setFiles', [])
    },
    addFile(file: File): void {
        store.dispatch('addFile', file).then()
    },
    setFiles(files: File[]): void {
        store.dispatch('setFiles', files).then()
    },
    updateFile(data: File): void {
        store.dispatch('updateFile', data).then()
    },
    removeFile(id: string): void {
        store.dispatch('removeFile', id).then()
    }
}