import {Role} from '@/shared/enums/Role'
import {MenuItem} from '@/shared/enums/MenuItem'

type MenuItemsByRoles = {
    [key in Role]: MenuItem[]
};

const menuItemsByRoles: MenuItemsByRoles = {
    [Role.LegacyAccess]: [
        MenuItem.Welcome,
        MenuItem.Invoices,
        MenuItem.AccountsPayable,
        MenuItem.Loads,
        MenuItem.Suppliers,
        MenuItem.Benefits,
        MenuItem.Tariff,
        MenuItem.Metrics
    ],
    [Role.Admin]: [
        MenuItem.Welcome,
        MenuItem.Invoices,
        MenuItem.AccountsPayable,
        MenuItem.Loads,
        MenuItem.Suppliers,
        MenuItem.Benefits,
        MenuItem.Tariff,
        MenuItem.Metrics,
        MenuItem.Customers,
        MenuItem.Settings
    ],
    [Role.PlanningAuditor]: [
        MenuItem.Welcome,
        MenuItem.Suppliers,
        MenuItem.Loads,
        MenuItem.Benefits,
        MenuItem.Metrics,
        MenuItem.Customers,
        MenuItem.Settings
    ],
    [Role.FinancialManagement]: [
        MenuItem.Welcome,
        MenuItem.Invoices,
        MenuItem.Suppliers,
        MenuItem.AccountsPayable,
        MenuItem.Benefits,
        MenuItem.Tariff,
        MenuItem.Metrics
    ],
    [Role.Supplier]: [
        MenuItem.Welcome,
        MenuItem.Loads,
        MenuItem.LoadsList,
        MenuItem.LoadDetail,
    ],
    [Role.CollabSupplier]: [
        MenuItem.Welcome,
        MenuItem.CobraCollab,
        MenuItem.Benefits
    ],
};

export default menuItemsByRoles;